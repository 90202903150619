import React, { useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Text from "../../utils/CustomText";
import { BsGrid, BsStar } from "react-icons/bs";
import { BiCog, BiLogOut, BiUserCircle } from "react-icons/bi";
import Logo from "../../assets/images/Logo.svg";
import { Template1, Template10, Template11, Template12, Template2, Template3, Template4, Template5, Template6, Template7, Template8, Template9 } from "../../assets/images/templates/Index";
import TemplatesSideBarCard from "./templates/TemplateSideBarCard";
import { useMediaQuery } from "react-responsive";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { SIGNOUT_USER, SIGNOUT_USER_SUCCESSFUL } from "../../redux/Types";
import LogoutModal from "./templates/LogoutModal";
const images = [
  {
    id: "12a",
    src: Template1,
  },
];
export const SideBar = ({ showSideBar, toggleSideBar, toggleLogOut }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const getPath = currentPath.split("/");
  let ActivePath = null;
  const navigate = useNavigate();
  const params = useParams();
  const getParamArr = Object.entries(params);
  const getID = getParamArr[0][1].split("/");
  const id = getID[1];
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  if (getPath.length === 2) {
    ActivePath = getPath[1];
  }
  if (getPath.length === 3) {
    ActivePath = getPath[2];
  }
  if (getPath.length === 4) {
    ActivePath = getPath[2];
  }
  const selectTemplate = (val) => {
    navigate(`menuMaker/${val?.id}`, {
      state: { data: val },
    });
  };

  return (
    <>
      <nav className={"sideBarContainer"} style={{ visibility: isDesktopOrLaptop || showSideBar ? "visible" : "hidden", right: isDesktopOrLaptop || showSideBar ? "0" : "10px" }}>
        <header className={"sideBarHeader"}>
          <div className={"flexRow alignCenter justifyBetween"}>
            <img src={Logo} alt="logo" />
            {showSideBar || !isDesktopOrLaptop ? (
              <>
                <MdClose color="#fff" onClick={toggleSideBar} size={28} />
              </>
            ) : (
              <></>
            )}
          </div>
          {ActivePath === "menuMaker" && (
            <div className={"sideBarTemplatesHeader"}>
              <Text>Templates</Text>
            </div>
          )}
        </header>
        {ActivePath !== `menuMaker` ? (
          <section>
            <ul>
              <li>
                <NavLink to={"/dashboard"} className={`flexRow alignCenter ${ActivePath === "dashboard" && "sideBarActiveLink"}`}>
                  <BsGrid size={22} />
                  <Text tag={"p"} className={"semiBoldText f16"}>
                    Templates
                  </Text>
                  {ActivePath === "dashboard" && <div className={"activeIndicator"} />}
                </NavLink>
              </li>
              <li>
                <NavLink className={`flexRow alignCenter ${ActivePath === "menu" && "sideBarActiveLink"}`} to={"menu"}>
                  <BsStar size={22} />
                  <Text tag={"p"} className={"semiBoldText f16"}>
                    My Menus
                  </Text>
                  {ActivePath === "menu" && <div className={"activeIndicator"} />}
                </NavLink>
              </li>
              <li>
                <NavLink className={`flexRow alignCenter ${ActivePath === "account" && "sideBarActiveLink"}`} to={"account"}>
                  <BiUserCircle size={22} />
                  <Text tag={"p"} className={"semiBoldText f16"}>
                    Account
                  </Text>
                  {ActivePath === "account" && <div className={"activeIndicator"} />}
                </NavLink>
              </li>
              <li>
                <NavLink className={`flexRow alignCenter ${ActivePath === "settings" && "sideBarActiveLink"}`} to={"settings"}>
                  <BiCog size={22} />
                  <Text tag={"p"} className={"semiBoldText f16"}>
                    Settings
                  </Text>
                  {ActivePath === "settings" && <div className={"activeIndicator"} />}
                </NavLink>
              </li>
              <li style={{ marginTop: "50px" }} onClick={toggleLogOut}>
                <NavLink className={`flexRow alignCenter`}>
                  <BiLogOut size={22} />
                  <Text tag={"p"} className={"semiBoldText f16"}>
                    Logout
                  </Text>
                </NavLink>
              </li>
            </ul>
          </section>
        ) : (
          <section style={{ paddingBottom: "100px" }}>
            <ul>
              {images?.map((image, index) => {
                return (
                  <li>
                    <TemplatesSideBarCard src={image.src} background={id !== image?.id ? "#F9F9FB" : "inherit"} border={id === image?.id ? "0.55px #FFA024 solid" : "none"} onClick={() => selectTemplate(image)} key={index} />
                  </li>
                );
              })}
            </ul>
          </section>
        )}
      </nav>
    </>
  );
};
