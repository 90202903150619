import React, { useState } from 'react';
import Text from '../../utils/CustomText';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'


const Accordion = ({ title, content }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="accordion">
            <div className="accordion-header flexRow alignCenter justifyBetween" onClick={toggleAccordion}>
                <Text tag="h4" className={`accordion-title f16 boldText ${isExpanded ? 'expanded' : ''}`}
                    style={{ color: "#000" }}
                >
                    {title}
                </Text>
                {isExpanded ? <AiOutlineMinusCircle size={16} color='#FFA024' /> : <AiOutlinePlusCircle size={16} color='#FFA024' />}
            </div>
            {isExpanded && (
                <div className="accordion-content">
                    <Text
                        tag={'p'} className={` f16 regularText`} style={{ color: '#667085' }}
                    >{content}</Text>
                </div>
            )}
        </div>
    );
};

export default Accordion;