import React from 'react'
import Text from '../../utils/CustomText'
import { useMediaQuery } from 'react-responsive'
import StepsListItem from './StepsListItem'
import MaasHow from '../../assets/images/massHow.png';

const GetStartedStepsSection = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (
        <section className={'getStartedStepsContainer textCenter'}>
            <Text
                tag="h2" className={`boldText ${isDesktopOrLaptop ? 'f52 pb100' : 'f38 pb24'}`}
                style={{ color: '#000' }}
            >
                Start using our virtual menu service in 4 easy steps.
            </Text>
            <div className={`flexRow flexWrap alignCenter`}>
                <ul style={{ width: isDesktopOrLaptop ? '50%' : '100%' }}>
                    <StepsListItem number={'1'} title={'Sign up and set up your profile'} description={'Click on “Get started” to create an account and start using our virtual menu service'} />
                    <StepsListItem number={'2'} title={'Create a custom menu'} description={'Choose a menu template of your choice and start customizing your food menu'} />
                    <StepsListItem number={'3'} title={'Get QR menu code and link'} description={'Share the QR menu code and link to your customers to start taking orders virtually.'} />
                    <StepsListItem number={'4'} title={'Start taking orders from customers, anywhere!'} description={'Your virtual menu allows customers view menu and order for items with one click.'} />

                </ul>
                <div style={{ width: isDesktopOrLaptop ? '50%' : '100%' }}>
                    <img src={MaasHow} alt='image' style={{ width: '80%', height: '80%', objectFit: 'contain' }} />

                </div>
            </div>
        </section>
    )
}

export default GetStartedStepsSection