import { GETTING_MENU, GETTING_MENU_FAIL, GETTING_MENU_SUCCESSFUL, GETTING_SINGLE_MENU, GETTING_SINGLE_MENU_FAIL, GETTING_SINGLE_MENU_SUCCESSFUL, UPDATE_MENU } from "../Types";

const INITIAL_STATE = {
    loading: false,
    error: [],
    menus: [],
    singleMenu: null,
    templateMenu: null

};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case GETTING_MENU:
            return { ...state, loading: true };
        case GETTING_MENU_SUCCESSFUL:
            return { ...state, loading: false, menus: payload }
        case GETTING_MENU_FAIL:
            return { ...state, loading: false, error: payload }
        case UPDATE_MENU:
            return { ...state, singleMenu: null }
        case GETTING_SINGLE_MENU:
            return { ...state, loading: true }
        case GETTING_SINGLE_MENU_SUCCESSFUL:
            return { ...state, loading: false, singleMenu: payload, templateMenu: payload }
        case GETTING_SINGLE_MENU_FAIL:
            return { ...state, loading: false, error: payload }

        default:
            return state;
    }
};