import React from 'react'
import Text from '../../utils/CustomText'

const KeyFeaturesCard = ({ src, title, description }) => {
    return (
        <section className={'keyFeaturesCard'}>
            <div className={'keyFeaturesImageContainer'}>
                <img src={src} alt='image' />
            </div>
            <div className={'keyFeaturesContentContainer'}>
                <Text tag="h3" className={'f24 boldText pb16'} style={{ color: '#181819' }}>
                    {title}
                </Text>
                <Text tag="p" className={'f16 regularText'} style={{ color: '#181819' }}>
                    {description}
                </Text>
            </div>
        </section>
    )
}

export default KeyFeaturesCard