import React from 'react'
import Text from '../../utils/CustomText'
import { useMediaQuery } from 'react-responsive'
import Accordion from './FaqAccordion'

const FaqSection = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (
        <section className={'faqContainer flexRow justifyBetween flexWrap'}>
            <div style={{ width: isDesktopOrLaptop ? '30%' : '100%' }}>
                <Text
                    tag="h2" className={isDesktopOrLaptop ? 'f52 boldText pb24' : 'f38 boldText pb24'}
                    style={{ color: '#101828' }}
                >
                    FAQs
                </Text>
                <Text
                    tag="p" className={isDesktopOrLaptop ? 'f20 regularText' : 'f16 regularText pb40'}
                    style={{ color: '#38383A' }}
                >
                    Everything you need to know about the product and service.
                </Text>
            </div>
            <div style={{ width: isDesktopOrLaptop ? '50%' : '100%' }}>
                <Accordion title={'What is the virtual menu service about?'} content={'It is feature that allows you to create custom menus for your food business and take customer orders virtually.'} />
                <Accordion title={'Is it free?'} content={'It is feature that allows you to create custom menus for your food business and take customer orders virtually.'} />
                <Accordion title={'Must I be a vendor on Dado to use the service?'} content={'It is feature that allows you to create custom menus for your food business and take customer orders virtually.'} />
                <Accordion title={'How long will it take to get the physical menu card?'} content={'It is feature that allows you to create custom menus for your food business and take customer orders virtually.'} />
                <Accordion title={'Can I make more than one custom menu?'} content={'It is feature that allows you to create custom menus for your food business and take customer orders virtually.'} />
                <Accordion title={'Sample question?'} content={'It is feature that allows you to create custom menus for your food business and take customer orders virtually.'} />

            </div>
        </section>
    )
}

export default FaqSection

