import React from 'react'
import Text from '../../utils/CustomText'
import { useMediaQuery } from 'react-responsive'

const StepsListItem = ({ number, title, description }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (
        <>
            <li className={'stepsListItemContainer'} style={{ margin: isDesktopOrLaptop ? '0 0 64px 0' : '0 0 40px 0' }}>
                <div className={'stepsListNumberContainer'}>
                    <Text
                        tag="h3" className={isDesktopOrLaptop ? 'f38 boldText' : 'f18 boldText'}
                        style={{ color: '#fff' }}
                    >
                        {number}
                    </Text>
                </div>
                <div>
                    <Text
                        tag="h4" className={isDesktopOrLaptop ? 'f24 boldText pb16' : 'f18 boldText pb16'}
                        style={{ color: '#000', textAlign: 'left' }}
                    >
                        {title}
                    </Text>
                    <Text
                        tag="p" className={isDesktopOrLaptop ? 'f20 regularTextText pb16' : 'f16 regularText pb16'}
                        style={{ color: '#38383A', textAlign: 'left' }}
                    >
                        {description}
                    </Text>
                </div>
            </li>
        </>

    )
}

export default StepsListItem