import React, { useEffect, useState } from "react";
import MenuImage from "../../../assets/images/menuImage.svg";
import Text from "../../../utils/CustomText";
import { AiOutlineEdit, AiOutlineLink, AiOutlineMore, AiOutlineQrcode } from "react-icons/ai";
import { CgLivePhoto } from "react-icons/cg";

import { useNavigate } from "react-router-dom";
import http from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_MENU } from "../../../redux/Types";
import { showError } from "../../../utils/Alert";

const MenuCard = ({ item, index }) => {
  const dateStr = item?.createdAt;
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(new Date(dateStr));

  const navigate = useNavigate();
  const { templateMenu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const editMenu = () => {
    navigate(`/dashboard/menuMaker/${item?.menuTemplateName}`, {
      state: { data: item },
    });
  };

  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState({});
  const toggleMenu = (val) => {
    setShowMenu(!showMenu);
    setSelectedMenu(val);
  };
  // console.log(templateMenu, "meu");

  const publishMenu = async () => {
    const businessId = selectedMenu?.businessId;
    const menuId = selectedMenu?.id;

    try {
      const res = await http.post(`business/${businessId}/menus/${menuId}/publish`);
      console.log(res, "publish menu");
      if (res) {
        navigate(`/dashboard/menuQRCode`, {
          state: { data: res?.data, menuData: selectedMenu },
        });
        dispatch({ type: UPDATE_MENU });
      }
    } catch (error) {
      // console.log(error);
      showError(error);
    }
  };

  return (
    <section className={"menuCardContainer"} key={index}>
      <div onClick={editMenu}>
        <img src={MenuImage} alt="template" />
      </div>
      <div className={"menuCardBtmContainer flexRow alignCenter justifyBetween"}>
        <div>
          <Text className={"capitalize semiBoldText f16"} tag={"h3"} style={{ color: "#000" }}>
            {item?.name}
          </Text>
          <Text className={"capitalize semiBoldText f12"} style={{ color: "#B1B2B4" }} tag={"p"}>
            Created: {formattedDate}
          </Text>
        </div>
        <AiOutlineMore size={22} onClick={() => toggleMenu(item)} style={{ cursor: "pointer" }} />
      </div>
      {showMenu && (
        <div className={"menuCardMenu"} style={{ height: "70px", bottom: "-100px" }}>
          <ul>
            <li className={"flexRow alignCenter"} style={{ gap: "20px", cursor: "pointer" }} onClick={editMenu}>
              <AiOutlineEdit color="#000" />
              <Text tag={"p"} className={"f14 regularText"} style={{ color: "#000" }}>
                Edit
              </Text>
            </li>
            <li className={"flexRow alignCenter"} style={{ gap: "20px", cursor: "pointer" }} onClick={publishMenu}>
              <CgLivePhoto color="#000" />
              <Text tag={"p"} className={"f14 regularText"} style={{ color: "#000" }}>
                Go live
              </Text>
            </li>
          </ul>
        </div>
      )}
    </section>
  );
};

export default MenuCard;
