import React, { useState } from 'react';
import { BiMenu } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { useMediaQuery } from 'react-responsive';
import Text from '../utils/CustomText';
import Logo from '../assets/images/Logo.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const Navbar = (props) => {
    const [mobileNav, setMobileNav] = useState(false)
    const toggleMobileNav = () => {
        setMobileNav(previousState => !previousState);
    }
    const { openAuth } = props
    const navigate = useNavigate()
    const { loggedIn } = useSelector(state => state.auth)
    console.log(loggedIn)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const toggleAuth = () => {
        toggleMobileNav()
        openAuth()
    }

    const goToDashboard = () => {
        navigate("/dashboard")
    }


    return (
        <>

            <nav className={'flexRow justifyBetween alignCenter navContainer'}>
                <section>
                    <img src={Logo} alt='LOGO' />
                </section>
                {isDesktopOrLaptop ?
                    <ul className={'flexRow justifyBetween alignCenter'}>
                        <li>
                            <a href='https://dado.ng/couriers'
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#919294' }}
                                >
                                    For Couriers
                                </Text>
                            </a>
                        </li>
                        <li>
                            <a href='https://dado.ng/vendors'
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#919294' }}
                                >
                                    For Vendors
                                </Text>
                            </a>
                        </li>
                        <li>
                            <Text tag={'p'} className={'f16 boldText'}>
                                Virtual Menu Service
                            </Text>
                        </li>
                        {loggedIn ?
                            <li onClick={goToDashboard}>
                                <Text tag={'p'} className={'f16 semiBoldText'}
                                    style={{ color: '#000', border: '1px solid #999CA0', borderRadius: '8px', padding: '12px 16px', cursor: 'pointer' }}
                                >Go to Dashboard</Text>
                            </li> : <li onClick={openAuth}>
                                <Text tag={'p'} className={'f16 semiBoldText'}
                                    style={{ color: '#000', border: '1px solid #999CA0', borderRadius: '8px', padding: '12px 16px', cursor: 'pointer' }}
                                >Login</Text>
                            </li>}
                    </ul> :
                    <button className='callButton'>
                        {mobileNav ? <AiOutlineClose size={33} onClick={toggleMobileNav} color={props.mobileBtnColor} />
                            : <BiMenu size={33} onClick={toggleMobileNav} color={props.mobileBtnColor} />}

                    </button>
                }
                {mobileNav &&
                    <ul className={'flexColumn mobileNav'}>
                        <li>
                            <a href='https://dado.ng/couriers'
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Text tag={'p'}>
                                    For Couriers
                                </Text>
                            </a>
                        </li>
                        <li>
                            <a href='https://dado.ng/vendors'
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Text tag={'p'}>
                                    For Vendors
                                </Text>
                            </a>
                        </li>
                        <li>
                            <Text tag={'p'}>
                                Virtual Menu Service
                            </Text>
                        </li>
                        <li onClick={toggleAuth}>
                            <Text tag={'p'} className={'f16 semiBoldText'}
                                style={{ color: '#000', border: '1px solid #999CA0', borderRadius: '8px', padding: '12px 16px', cursor: 'pointer', width: '80px' }}
                            >Login</Text>
                        </li>
                    </ul>}
            </nav>
        </>
    )
}

export default Navbar