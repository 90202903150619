import React from 'react'
import Text from '../../utils/CustomText'
import { useMediaQuery } from 'react-responsive'

const AboutSection = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    return (
        <section className={'aboutContainer textCenter'}>
            <Text
                tag="h2" className={isDesktopOrLaptop ? 'f52 boldText pb24' : 'f38 boldText pb24'}
                style={{ color: '#000' }}
            >
                About the Virtual Menu Service
            </Text>
            <Text
                tag="p" className={isDesktopOrLaptop ? 'f32 regularText' : 'f20 regularText'}
                style={{ color: '#38383A' }}
            >
                The Dado virtual menu service is a free vendor feature on Dado that helps vendors create custom virtual menus and update their available meals in real time. With virtual menu service, vendors can easily create, manage and share their food menus with customers anywhere.
            </Text>
        </section>
    )
}

export default AboutSection



