import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import Text from "../../utils/CustomText";
import { useMediaQuery } from "react-responsive";
import FooterImage from "../../assets/images/shapes.png";

const AuthModalContainer = ({ title, subTitle, children, back, closeAuth }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <section className={`authModalContainer`}>
      <div className={`authModalContentContainer  ${isDesktopOrLaptop ? "animate__animated animate__fadeIn" : "animate__animated animate__slideInUp"}`} style={{ width: isDesktopOrLaptop ? "416px" : "100%" }}>
        <header className={" authModalContentHeader"}>
          <div className={"flexRow alignCenter justifyBetween"}>
            {back ? (
              <button>
                <BiArrowBack />
              </button>
            ) : (
              <div />
            )}
            <button onClick={closeAuth}>
              <GrClose />
            </button>
          </div>
          <div>
            <Text tag={"h2"} className={`boldText textCenter pb8 ${isDesktopOrLaptop ? "f32" : "f24"}`}>
              {title}
            </Text>
            <Text tag={"p"} className={`regularText textCenter pb16 ${isDesktopOrLaptop ? "f18" : "f16"}`} style={{ lineHeight: "30px", wordWrap: "break-word" }}>
              {subTitle}
            </Text>
          </div>
        </header>

        <div className={"authModalFormContainer"}>{children}</div>
        {isDesktopOrLaptop && (
          <footer className={"authModalContainerFooter"}>
            <img src={FooterImage} alt="footerImage" />
          </footer>
        )}
      </div>
    </section>
  );
};

export default AuthModalContainer;
