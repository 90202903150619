export const RE_DIGIT = new RegExp(/^\d+$/);

export const setToken = async (data) => {
    try {
        const accessToken = data.accessToken;
        console.log(accessToken, 'token')
        localStorage.setItem("accessToken", accessToken);
    } catch (error) {
        console.log("Could not set accessToken ", error.message);
    }
}


export const shortenXterLength = (string, number = 10) => {
    if (string) {
        if (string.length <= number) {
            return string
        }
        return `${string.slice(0, number)}..`
    }
    return ''
}


export const setLocation = async (data) => {
    try {
        localStorage.setItem("location", data);
    } catch (error) {
        console.log("Could not set location ", error.message);
    }
}

export const storeId = async (data) => {
    try {
        localStorage.setItem("businessId", data);
    } catch (error) {
        console.log("Could not store id ", error.message);
    }
}

export const retrieveStoredId = () => {
    try {
        const data = localStorage.getItem("businessId");
        return data;
    } catch (error) {
        console.log("Could not retrieve id ", error.message);
        return null; // Handle the error as needed
    }
};

export const storeBusiness = async (data) => {
    try {
        localStorage.setItem("business", data);
    } catch (error) {
        console.log("Could not store business ", error.message);
    }
}

export const retrieveStoredBusiness = () => {
    try {
        const data = localStorage.getItem("business");
        return data;
    } catch (error) {
        console.log("Could not retrieve business ", error.message);
        return null; // Handle the error as needed
    }
};

export const storeBusinessMenu = async (data) => {
    try {
        localStorage.setItem("businessMenu", data);
    } catch (error) {
        console.log("Could not store business menu ", error.message);
    }
}

export const retrieveStoredBusinessMenu = () => {
    try {
        const data = localStorage.getItem("businessMenu");
        return data;
    } catch (error) {
        console.log("Could not retrieve business menu ", error.message);
        return null; // Handle the error as needed
    }
};


export const formatAmount = (amount, maxDigit = 2) => {
    if (Number(amount) === 0 || amount) {
        return Number(amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: maxDigit,
        });
    }

    return '***';
}
