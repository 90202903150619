import React, { useState } from "react";
import CustomInput from "../../utils/CustomInput";
import Button from "../../utils/CustomButton";
import Text from "../../utils/CustomText";
import { useMediaQuery } from "react-responsive";
import { loginReq } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../utils/Alert";

const SignInForm = ({ toggleForgot }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [state, setState] = useState({
    email: "",
    password: "",
    error: null,
    loading: false,
  });

  const onChangeEmail = (e) => {
    setState({ ...state, email: e.target.value });
  };
  const onChangePassword = (e) => {
    setState({ ...state, password: e.target.value });
  };

  const disabled = () => {
    const { email, password, loading } = state;
    if (email === "" || password === "" || loading) {
      return true;
    }
    return false;
  };

  const login = async () => {
    const { email, password } = state;
    const obj = { email, password };
    setState({ ...state, loading: true });
    try {
      const res = await dispatch(loginReq(obj));
      setState({ ...state, loading: false });
      console.log(res, "login res");
      // showSuccess()
      if (!res?.successfullyOnboarded) {
        navigate("onBoarding");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      setState({ ...state, loading: false });
      showError(error);
    }
  };
  //  disabled ? "#FFC06D" :
  return (
    <section>
      <CustomInput type={"email"} label={"Email address*"} placeholder={"Enter email address "} onChange={onChangeEmail} />
      <CustomInput type={"password"} label={"Password*"} placeholder={"Enter unique password "} onChange={onChangePassword} />
      <Button text={"Forgot password?"} style={{ background: "inherit", border: "none", textDecoration: "underline" }} onClick={toggleForgot} />
      <Button text={"Continue"} className={"authButton"} style={{ background: "#FF9000", marginTop: "20px", opacity: disabled() && "0.4" }} onClick={login} loading={state.loading} disabled={disabled()} />
      <div className={"termsContainer"} style={{ width: isDesktopOrLaptop ? "319px" : "100%" }}>
        <Text tag={"p"} className={"f12 regularText textCenter"} style={{ color: "#919294" }}>
          By continuing you agree to the
          <Text tag={"a"} className={"f14 mediumText"} style={{ color: "#181819", textDecoration: "underline", cursor: "pointer" }} href="https://dado.ng/privacy">
            {" "}
            Privacy{" "}
          </Text>
          <Text tag={"a"} className={"f14 mediumText"} style={{ color: "#181819", textDecoration: "underline", cursor: "pointer" }} href="https://dado.ng/terms">
            Terms & Conditions{" "}
          </Text>
          without reservation
        </Text>
      </div>
    </section>
  );
};

export default SignInForm;
