import React from "react";
import Text from "../../utils/CustomText";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BiMenu } from "react-icons/bi";
import Logo from "../../assets/images/Logo.svg";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

const TopNav = ({ toggleSideBar }) => {
  const { businessProfile } = useSelector((state) => state.auth);
  // console.log(businessProfile, "d");

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const location = useLocation();
  const currentPath = location.pathname;
  const getPath = currentPath.split("/");
  let ActivePath = null;
  if (getPath.length === 2) {
    ActivePath = getPath[1];
  }
  if (getPath.length === 3) {
    ActivePath = getPath[2];
  }
  if (getPath.length === 4) {
    ActivePath = getPath[2];
  }
  const navigate = useNavigate();
  const goToDashboard = () => {
    navigate(-1);
  };

  const inputString = businessProfile?.business?.businessName;
  const firstLetter = inputString ? inputString[0] : null;
  const lastLetter = inputString ? inputString[inputString.length - 1] : null;

  const addressArray = businessProfile?.business?.businessAddress?.split(",");

  // Display the last two items in the array
  const lastTwoItems = addressArray?.slice(-2);

  // console.log(lastTwoItems);
  const renderState = () => {
    if (lastTwoItems) {
      return lastTwoItems?.map((item, index) => {
        return (
          <Text tag="p" className="f14 regularText" style={{ color: "#fff" }} key={index}>
            {index === 0 ? item + ", " : item}
          </Text>
        );
      });
      return null; // You may want to handle the case when lastTwoItems is not defined.
    }
  };
  return (
    <nav className="topNavContainer flexRow alignCenter justifyBetween">
      {!isDesktopOrLaptop ? (
        <section className={"flexRow alignCenter justifyBetween"}>
          <img src={Logo} alt="logo" />
        </section>
      ) : (
        <div className={"flexRow alignCenter"} style={{ gap: "10px", color: "#fff", cursor: "pointer", marginLeft: isDesktopOrLaptop ? "220px" : "0" }} onClick={goToDashboard}>
          {ActivePath === "menuMaker" ||
            (ActivePath === "menuQRCode" && (
              <>
                <MdKeyboardArrowLeft color="#fff" />
                <Text tag={"p"} className={"f16 semiBoldText"}>
                  Back
                </Text>
              </>
            ))}
        </div>
      )}
      {isDesktopOrLaptop ? (
        <section className={"flexRow alignCenter"} style={{ gap: "20px" }}>
          <div>
            <Text tag={"p"} className={"f16 boldText capitalize"} style={{ color: "#fff" }}>
              {businessProfile?.business?.businessName}
            </Text>
            <div className={"flexRow alignCenter"} style={{ gap: "5px" }}>
              {renderState()}
            </div>
          </div>

          <div className={"avatarContainer"}>
            <Text tag={"p"} className={"f14 boldText upper"}>
              {firstLetter}
              {lastLetter}
            </Text>
          </div>
        </section>
      ) : (
        <section>
          <BiMenu size={33} color="#fff" onClick={toggleSideBar} />
        </section>
      )}
    </nav>
  );
};

export default TopNav;
