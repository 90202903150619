import React, { useState } from "react";
import CustomInput from "../../utils/CustomInput";
import Button from "../../utils/CustomButton";
import Text from "../../utils/CustomText";
import { useMediaQuery } from "react-responsive";
import { loginReq } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess, showWarning } from "../../utils/Alert";
import http from "../../Utils";

const ResetPasswordForm = ({ toggleLogin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [state, setState] = useState({
    confirmPassword: "",
    password: "",
    error: null,
    loading: false,
  });

  const onChangeConfirmPassword = (e) => {
    setState({ ...state, confirmPassword: e.target.value });
  };
  const onChangePassword = (e) => {
    setState({ ...state, password: e.target.value });
  };

  const resetPass = async () => {
    const { password, confirmPassword } = state;
    const obj = { password };
    setState({ ...state, loading: true });
    if (confirmPassword === password) {
      try {
        const res = await http.post(`auth/reset_password/new_password`, obj);
        setState({ ...state, loading: false });
        showSuccess(res?.message);
        if (res) {
          toggleLogin();
        }
      } catch (error) {
        setState({ ...state, loading: false });
        showError(error);
      }
    }
    if (confirmPassword !== password) {
      setState({ ...state, loading: false });

      showWarning("Password does not match");
    }
  };
  //  disabled ? "#FFC06D" :
  return (
    <section>
      <CustomInput type={"password"} label={"Password*"} placeholder={"Enter unique password "} onChange={onChangePassword} />
      <CustomInput type={"password"} label={"Confirm password*"} placeholder={"Enter unique password "} onChange={onChangeConfirmPassword} />
      <Button text={"Continue"} className={"authButton"} style={{ background: "#FF9000", marginTop: "20px" }} onClick={resetPass} loading={state.loading} />
    </section>
  );
};

export default ResetPasswordForm;
