import React from "react";
import Button from "../../../utils/CustomButton";
import Text from "../../../utils/CustomText";
import { CiLocationOn, CiPhone, CiMail } from "react-icons/ci";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import Logo from "../../../assets/images/Logo.svg";

const UserInfoCard = ({ item }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const { businessProfile } = useSelector((state) => state.auth);

  return (
    <section className={"userInfoContainer"}>
      <div className={"flexRow justifyBetween"}>
        <picture>
          <img src={businessProfile?.business?.businessLogo} alt="user-logo" defaultValue={Logo} style={{ height: "100px", width: "100px", borderRadius: "99px", objectFit: "cover" }} />
        </picture>
        <div className={`${isDesktopOrLaptop ? "alignSelfEnd" : "alignSelfCenter"}`}>{/* <Button text={"Edit profile"} className={"editButton"} /> */}</div>
      </div>
      <div>
        <Text tag={"p"} className={"f24 boldText capitalize"}>
          {businessProfile?.business?.businessName}
        </Text>
        <ul>
          <li className={"flexRow alignCenter"} style={{ margin: "10px 0" }}>
            <CiLocationOn size={22} color="#919294" />
            <Text tag={"address"} className={"regularText f14"} style={{ color: "#919294", marginLeft: "5px" }}>
              {businessProfile?.business?.businessAddress}
            </Text>
          </li>
          <li className={"flexRow alignCenter"}>
            <CiMail size={22} color="#919294" />
            <Text tag={"span"} className={"regularText f14"} style={{ color: "#919294", marginLeft: "5px" }}>
              {businessProfile?.business?.businessEmail}
            </Text>
          </li>
          <li className={"flexRow alignCenter"} style={{ margin: "10px 0" }}>
            <CiPhone size={22} color="#919294" />
            <Text tag={"span"} className={"regularText f14"} style={{ color: "#919294", marginLeft: "5px" }}>
              {businessProfile?.business?.businessPhone[0]}
            </Text>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default UserInfoCard;
