import React, { useRef, useState } from "react";
import Button from "../../utils/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../utils/CustomText";
import Template1 from "../../components/dashboard/templates/Template1";

import * as htmlToImage from "html-to-image";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_MENU } from "../../redux/Types";
import { useMediaQuery } from "react-responsive";

const MenuMaker = () => {
  // const location = useLocation();
  // const data = location.state;
  // console.log(data.src);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showControls, setShowControls] = useState(true);
  const toggleControls = () => {
    setShowControls(!showControls);
  };
  const goToPreview = () => {
    navigate("/dashboard/menuPreview", {});
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <main className={"accountsContainer"}>
      <header className={"flexRow alignCenter justifyBetween  flexWrap"} style={{ gap: "10px", position: "fixed", top: "80px", left: isDesktopOrLaptop ? "300px" : "20px", right: isDesktopOrLaptop ? "80px" : "20px", background: isDesktopOrLaptop ? "transparent" : "#fff", padding: "20px 0", backgroundColor: "#fff" }}>
        <aside>
          <Text tag={"h2"} className={"f24 boldText"}>
            Menu maker
          </Text>
          <Text tag={"p"} className={"f14 regularText greyText"}>
            Tap on any section to start editing menu.
          </Text>
        </aside>
        <aside className={"flexRow alignCenter"} style={{ gap: "16px" }}>
          {showControls ? (
            <Button text={"Preview"} style={{ width: "76px", background: "#FFDFB6", border: "none", height: "32px", color: "#FFA024", fontSize: "14px" }} onClick={toggleControls} />
          ) : (
            <>
              <Button text={"Continue editing"} style={{ width: "129px", background: "#FFF", border: "none", height: "32px", color: "#000", fontSize: "14px" }} onClick={toggleControls} />
              <Button text={"Proceed"} style={{ width: "105px", background: "#FFA024", border: "none", height: "32px", color: "#Fff", fontSize: "14px" }} onClick={goToPreview} />
            </>
          )}
        </aside>
      </header>
      <section className={"templateEditorContainer"} style={{ marginTop: isDesktopOrLaptop ? "" : "40px" }}>
        <Template1 showControls={showControls} />
      </section>
    </main>
  );
};

export default MenuMaker;
