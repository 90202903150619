import React from "react";
import Text from "../../utils/CustomText";
import MenuDone from "../../assets/images/menuDone.svg";
import Button from "../../utils/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_MENU } from "../../redux/Types";
import http from "../../Utils";
// import QRCode from "react-qr-code";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";

const MenuPreview = () => {
  const { templateMenu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let url = `https://dadofoodmass.vercel.app/business`;
  const publishMenu = async () => {
    const businessId = templateMenu?.businessMenu?.businessId;
    const menuId = templateMenu?.businessMenu?.id;
    const menuUrl = `${url}/${businessId}/menu/${menuId}`;
    if (templateMenu?.businessMenu?.isPublished) {
      navigate(`/dashboard/menuQRCode`, {
        state: {
          data: menuUrl,
          menuData: templateMenu?.businessMenu,
        },
      });
    }
    try {
      const res = await http.post(`business/${businessId}/menus/${menuId}/publish`);
      console.log(res, "publish menu");
      if (res) {
        navigate(`/dashboard/menuQRCode`, {
          state: { data: res?.data },
        });
        dispatch({ type: UPDATE_MENU });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className={"accountsContainer"} style={{ height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
      <header>
        <Text tag={"h2"} className={"textCenter f30 boldText"} style={{ color: "rgba(145, 146, 148, 1)" }}>
          {templateMenu?.businessMenu?.isPublished ? "You just Updated your Menu!" : "  You just created a Menu!"}
        </Text>
      </header>
      <div>
        <img src={MenuDone} alt="done" />
      </div>
      <div>
        <Text tag={"p"} className={"f16 regularText textCenter"} style={{ color: "#000", padding: "10px 0" }}>
          {templateMenu?.businessMenu?.isPublished ? "Share your updated menu with your customers." : "   Start sharing your menu to your customers."}
        </Text>
      </div>
      {/* <QRCode title="Menu" value={"https://dadofoodmass.vercel.app/business/7/menu/12"} bgColor={"#000"} fgColor={"#fff"} size={200} /> */}
      {/* <QRCode value="https://dadofoodmass.vercel.app/business/7/menu/12" style={{ marginRight: 50 }} /> */}
      <div>
        <Button text={"Go live!"} style={{ width: "105px", background: "#FFA024", border: "none", height: "48px", color: "#Fff", fontSize: "14px" }} onClick={publishMenu} />
      </div>
    </section>
  );
};

export default MenuPreview;
