import React from "react";
import Text from "../../../utils/CustomText";
import OnboardingCards from "../../../components/auth/OnboardingCards";
import Button from "../../../utils/CustomButton";
import SetupImage from "../../../assets/images/layer1.svg";
import ShareImage from "../../../assets/images/layer2.svg";
import StartImage from "../../../assets/images/layer3.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const OnBoarding = () => {
  const navigate = useNavigate();
  const goToProfileSetup = () => {
    navigate("/profileSetup");
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <>
      <main className={"gradientBg"}>
        <section className="onBoardingSection">
          <header className={"onBoardingHeader"}>
            <Text tag={"h2"} className={"textCenter"}>
              Welcome to
              <Text> Dado </Text>
              Virtual Menu Service
            </Text>
            <Text tag={"p"} className={"textCenter"}>
              ...what’s next?
            </Text>
          </header>
          <div className={"onBoardingFlowGrid"}>
            <OnboardingCards title={"Set up profile"} subTitle={"Enter required business details to set up your profile"} img={SetupImage} />
            <OnboardingCards title={"Start creating menus"} subTitle={"Choose a template and create your own menu card"} img={StartImage} />
            <OnboardingCards title={"Share menus to customers"} subTitle={"Upload menu live and share to your customers"} img={ShareImage} />
          </div>
          <div style={{ textAlign: "center" }}>
            <Button text={`Start using Dado Virtual Menu Service`} style={{ background: "#FF9000", border: "none", color: "#fff", marginTop: isDesktopOrLaptop ? "98px" : "50px", width: isDesktopOrLaptop ? "343px" : "100%" }} onClick={goToProfileSetup} />
          </div>
        </section>
      </main>
    </>
  );
};

export default OnBoarding;
