import React from "react";
import Text from "../../utils/CustomText";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../utils/CustomButton";
import { showSuccess, showWarning } from "../../utils/Alert";
import { CopyToClipboard } from "react-copy-to-clipboard";

const MenuQRCode = () => {
  const location = useLocation();
  const data = location.state;
  console.log(data?.data);
  const url = data?.data?.menuUrl ? data?.data?.menuUrl : data?.data;
  const { templateMenu } = useSelector((state) => state.menu);
  const logo = data?.menuData?.menuLogo ? data?.menuData?.menuLogo : templateMenu?.businessMenu?.menuLogo;

  return (
    <section className={"aboutContainer"}>
      <div className={"qrCodeCard"}>
        <div className={"qrCodeAvatar"}>
          <img src={logo} style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: "99px" }} />
        </div>
        <div style={{ textAlign: "center", padding: "48px 0" }}>
          <Text tag={"h2"} className={"f40 boldText"} style={{ color: "#fff" }}>
            SCAN ME
          </Text>
          <Text tag={"p"} className={"f16 mediumText"} style={{ color: "#fff" }}>
            TO ORDER
          </Text>
        </div>
        <div style={{ textAlign: "center" }}>
          <QRCode title="Menu" value={url} bgColor={"#fff"} fgColor={"#000"} size={250} style={{ margin: "auto" }} />
        </div>
        <div style={{ textAlign: "center", marginTop: "100px" }}>
          <Text className={"textCenter f10"} style={{ color: "#FF9000" }}>
            powered by Dado Foods
          </Text>
        </div>
      </div>
      <div className={"flexRow alignCenter justifyCenter"} style={{ gap: "24px", padding: "40px 0" }}>
        <div>
          {/* <Button text={"Copy Link"} style={{}} onClick={() => showWarning("Contact support")} /> */}
          <CopyToClipboard text={url} onCopy={() => showSuccess("Copied")} style={{ height: "30px", width: "99px", background: "#ff9000", border: "none", color: "#fff", borderRadius: "8px", padding: "12px 24px", cursor: "pointer" }}>
            <Text className={"f14 mediumText"} style={{ color: "#fff" }}>
              Copy Link
            </Text>
          </CopyToClipboard>
        </div>
        {/* <div>
          <Button text={"Print"} style={{ background: "#ff9000", border: "none", color: "#fff", padding: "5px 24px" }} onClick={() => showWarning("Contact support")} />
        </div> */}
      </div>
    </section>
  );
};

export default MenuQRCode;
