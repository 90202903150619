import React, { useState } from 'react'
import HeroSection from '../../components/home/HeroSection'
import AboutSection from '../../components/home/AboutSection'
import KeyFeaturesSection from '../../components/home/KeyFeaturesSection'
import GetStartedStepsSection from '../../components/home/GetStartedStepsSection'
import FaqSection from '../../components/home/FaqSection'
import Navbar from '../../widgets/Nav'
import NewsLetterComponent from '../../widgets/NewsLetter'
import Footer from '../../widgets/Footer'
import AuthModalContainer from '../../components/auth/AuthModalContainer'
import SignInForm from '../../components/auth/SignInForm'
import SignUpForm from '../../components/auth/SignUpForm'
import VerifyForm from '../../components/auth/VerifyForm'
import AuthRedirectModalContainer from '../../components/auth/AuthRedirectModal'
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm'
import ResetPasswordForm from '../../components/auth/ResetPasswordForm'

const Home = () => {

    const [showAuth, setShowAuth] = useState(false);
    const [authFlow, setAuthFlow] = useState('signup');
    const [authRedirect, setAuthRedirect] = useState(true);
    const [reset, setReset] = useState(false)
    const hideScroll = () => {
        document.body.style.overflow = "hidden"
    }
    const unhideScroll = () => {
        document.body.style.overflow = "unset"
    }
    const openAuth = () => {
        setShowAuth(true)
        hideScroll()
    }
    const closeAuth = () => {
        setShowAuth(false)
        setAuthFlow('signup')
        setAuthRedirect(true)
        unhideScroll()
    }

    const toggleLogin = () => {
        setAuthFlow('login')
        setAuthRedirect(false)
    }
    const closeRedirectModal = () => {
        setAuthRedirect(false);
    }

    const toggleVerify = () => {
        setAuthFlow('verify')
        if (authFlow === 'forgotPassword') {
            setReset(true)
        }
    }
    const toggleForgot = () => {
        setAuthFlow('forgotPassword')
    }
    const toggleReset = () => {
        setAuthFlow('resetPassword')
    }
    // console.log(reset)

    let title, subTitle;

    if (authFlow === 'login') {
        title = 'Login';
        subTitle = 'Enter your details to continue where you left.';
    } else if (authFlow === 'signup') {
        title = 'Sign Up';
        subTitle = 'Fill in the details correctly to create an account for the virtual menu service.';
    } else if (authFlow === 'verify') {
        title = 'Verify email';
        subTitle = 'Please enter the code sent to your email.';
    }
    else if (authFlow === 'forgotPassword') {
        title = 'Forgot password';
        subTitle = 'Enter the email address you registered with.';
    }
    else if (authFlow === 'resetPassword') {
        title = 'Reset password';
        subTitle = 'Create a new password for your account';
    }


    return (
        <>
            <Navbar openAuth={openAuth} />
            <main>
                <HeroSection openAuth={openAuth} />
                <AboutSection />
                <KeyFeaturesSection />
                <GetStartedStepsSection />
                <FaqSection />
            </main>
            <NewsLetterComponent />
            <Footer />
            {showAuth && <AuthModalContainer title={title} subTitle={subTitle} closeAuth={closeAuth}>
                {authFlow === 'login' && <SignInForm toggleVerify={toggleVerify} toggleForgot={toggleForgot} />}
                {authFlow === 'signup' && <SignUpForm toggleVerify={toggleVerify} />}
                {authFlow === 'verify' && <VerifyForm toggleLogin={toggleLogin} reset={reset} toggleReset={toggleReset} />}
                {authFlow === 'forgotPassword' && <ForgotPasswordForm toggleVerify={toggleVerify} />}
                {authFlow === 'resetPassword' && <ResetPasswordForm toggleLogin={toggleLogin} />}

            </AuthModalContainer>}
            {authFlow === 'signup' && showAuth && authRedirect && <AuthRedirectModalContainer loginModal={toggleLogin} closeModal={closeRedirectModal} />}

        </>
    )
}

export default Home