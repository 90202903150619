import React from 'react'
import Home from '../pages/home/Home'

import { Route, Routes } from 'react-router-dom'
import OnBoarding from '../pages/auth/onBoarding/OnBoarding'
import ProfileSetup from '../pages/auth/onBoarding/ProfileSetup'
import DashboardLayout from '../pages/dashboard/Dashboard'
import DisplayTemplate from '../pages/menu/DisplayTemplate'

const RoutesContainer = () => {

    return (
        <>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/onBoarding' element={<OnBoarding />} />
                <Route path='/profileSetup' element={<ProfileSetup />} />
                <Route path='/dashboard/*' element={<DashboardLayout />} />
                <Route path='/business/:id/menu/:id' element={<DisplayTemplate />} />


            </Routes >
        </>
    )
}

export default RoutesContainer