import React, { useEffect, useRef, useState } from "react";
import OtpInput from "../../utils/CustomOtp";
import Button from "../../utils/CustomButton";
import Text from "../../utils/CustomText";
import { useMediaQuery } from "react-responsive";
import { loginReq, verifyOtp } from "../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../utils/Alert";
import http from "../../Utils";

const VerifyForm = ({ toggleReset, reset }) => {
  const [pin, setPin] = useState("");
  const [count, setCount] = useState(90);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const { verifyToken, autoLoginDetails } = useSelector((state) => state.auth);
  // console.log(autoLoginDetails, "autoLogin");
  const navigate = useNavigate();
  const onChange = (value) => {
    setPin(value);
  };
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00");
  const [errors, setErrors] = useState([]);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer((minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("01:30");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 90);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());

    // console.log(phone, country_code)
  };

  const resendOtp = async () => {
    const obj = { email: autoLoginDetails?.email };
    setLoading(true);
    try {
      const res = await http.post(`auth/sign_up/resend_verification_token`, obj);
      setLoading(false);
      // console.log(res, "login res");
      showSuccess("Code Sent!");
      onClickReset();
    } catch (error) {
      showError(error);
      // console.log(error);
    }
  };

  const renderButton = () => {
    if (timer === "00:00") {
      return (
        <Text tag={"p"} className={"textCenter f14 mediumText pVertical16"} onClick={resendOtp} style={{ cursor: "pointer" }}>
          Resend code
        </Text>
      );
    }
    return (
      <div className={"flexRow alignCenter justifyCenter"}>
        <Text tag={"p"} className={"textCenter f14 mediumText pVertical16"}>
          {`Didn’t receive OTP?` + " "}
        </Text>
        <Text className={"textCenter f14 mediumText"}>
          {""}
          {` Resend OTP in ${timer} secs`}
        </Text>
      </div>
    );
  };

  const login = async () => {
    // const { email, password } = state;
    const obj = { email: autoLoginDetails?.email, password: autoLoginDetails?.password };
    setLoading(true);
    try {
      const res = await dispatch(loginReq(obj));
      setLoading(false);
      // console.log(res, "login res");
      showSuccess("Account Verified");
      if (!res?.successfullyOnboarded) {
        navigate("onBoarding");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  const verify = async () => {
    const otp = pin;
    const token = verifyToken?.authToken;
    const obj = { otp, token };
    setLoading(true);
    try {
      const res = await dispatch(verifyOtp(obj));
      setLoading(false);
      if (res && !reset) {
        login();
      }
      if (res && reset) {
        toggleReset();
      }
      // console.log(res, "verify res");
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  return (
    <section>
      <OtpInput value={pin} valueLength={6} onChange={onChange} />
      {renderButton()}
      <div style={{ textAlign: "center" }}>
        <Button text={"Next"} style={{ background: "#FFA024", border: "none", width: isDesktopOrLaptop ? "386px" : "100%", color: "#fff" }} loading={loading} onClick={verify} />
      </div>
    </section>
  );
};

export default VerifyForm;
