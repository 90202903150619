import React, { useState } from "react";
import CustomInput from "../../utils/CustomInput";
import Button from "../../utils/CustomButton";
import { useMediaQuery } from "react-responsive";
import { forgotPasswordOtpReq } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../utils/Alert";

const ForgotPasswordForm = ({ toggleVerify }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [state, setState] = useState({
    email: "",
    error: null,
    loading: false,
  });

  const onChangeEmail = (e) => {
    setState({ ...state, email: e.target.value });
  };

  const requestOtp = async () => {
    const { email } = state;
    const obj = { email };
    setState({ ...state, loading: true });
    try {
      const res = await dispatch(forgotPasswordOtpReq(obj));
      setState({ ...state, loading: false });

      showSuccess(res?.message);
      toggleVerify();
    } catch (error) {
      setState({ ...state, loading: false });
      showError(error);
    }
  };
  //  disabled ? "#FFC06D" :
  return (
    <section>
      <CustomInput type={"email"} label={"Email address*"} placeholder={"Enter email address "} onChange={onChangeEmail} />
      <Button text={"Send OTP"} className={"authButton"} style={{ background: "#FF9000", marginTop: "20px" }} onClick={requestOtp} loading={state.loading} />
    </section>
  );
};

export default ForgotPasswordForm;
