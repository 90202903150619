import React, { useEffect, useState } from "react";
import MenuImage from "../../../assets/images/menuImage.svg";
import Text from "../../../utils/CustomText";
import { AiOutlineEdit, AiOutlineLink, AiOutlineMore, AiOutlineQrcode } from "react-icons/ai";
import { CgLivePhoto } from "react-icons/cg";

import { useNavigate } from "react-router-dom";
import http from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_MENU } from "../../../redux/Types";
import { showError, showSuccess } from "../../../utils/Alert";
import { CopyToClipboard } from "react-copy-to-clipboard";

const LiveMenuCard = ({ item, index, dropdownRef }) => {
  const dateStr = item?.createdAt;
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(new Date(dateStr));
  // console.log(item, "item");

  const navigate = useNavigate();
  const { templateMenu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const editMenu = () => {
    setLive(!showLiveMenu);
    navigate(`/dashboard/menuMaker/${item?.menuTemplateName}`, {
      state: { data: item },
    });
  };
  // console.log(templateMenu, "meu");

  const [showLiveMenu, setLive] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState({});

  const toggleLiveMenu = (val) => {
    setLive(!showLiveMenu);
    setSelectedMenu(val);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef?.current && !dropdownRef?.current.contains(event.target)) {
      setLive(false);
    }
  };

  useEffect(() => {
    if (showLiveMenu) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showLiveMenu]);

  let url = `https://dadofoodmass.vercel.app/business`;
  const urlShare = `https://dadofoodmass.vercel.app/business/${selectedMenu?.businessId}/menu/${selectedMenu?.id}`;

  const publishMenu = async () => {
    setLive(!showLiveMenu);
    const businessId = selectedMenu?.businessId;
    const menuId = selectedMenu?.id;
    const menuUrl = `${url}/${businessId}/menu/${menuId}`;
    if (selectedMenu?.isPublished) {
      navigate(`/dashboard/menuQRCode`, {
        state: {
          data: menuUrl,
          menuData: selectedMenu,
        },
      });
    } else {
      try {
        const res = await http.post(`business/${businessId}/menus/${menuId}/publish`);
        console.log(res, "publish menu");
        if (res) {
          navigate(`/dashboard/menuQRCode`, {
            state: { data: res?.data },
          });
          dispatch({ type: UPDATE_MENU });
        }
      } catch (error) {
        // console.log(error);
        showError(error);
      }
    }
  };

  return (
    <section className={"menuCardContainer"} key={index}>
      <div onClick={editMenu}>
        <img src={MenuImage} alt="template" />
      </div>
      <div className={"menuCardBtmContainer flexRow alignCenter justifyBetween"}>
        <div>
          <Text className={"capitalize semiBoldText f16"} tag={"h3"} style={{ color: "#000" }}>
            {item?.name}
          </Text>
          <Text className={"capitalize semiBoldText f12"} style={{ color: "#B1B2B4" }} tag={"p"}>
            Created: {formattedDate}
          </Text>
        </div>
        <AiOutlineMore size={22} onClick={() => toggleLiveMenu(item)} style={{ cursor: "pointer" }} />
      </div>
      {showLiveMenu && (
        <div className={"menuCardMenu"}>
          <ul>
            <li className={"flexRow alignCenter"} style={{ gap: "20px", cursor: "pointer" }} onClick={editMenu}>
              <AiOutlineEdit color="#000" />
              <Text tag={"p"} className={"f14 regularText"} style={{ color: "#000" }}>
                Edit
              </Text>
            </li>
            <li className={"flexRow alignCenter"} style={{ gap: "20px", cursor: "pointer" }} onClick={publishMenu}>
              <AiOutlineQrcode color="#000" />
              <Text tag={"p"} className={"f14 regularText"} style={{ color: "#000" }}>
                QR code"
              </Text>
            </li>

            <li className={"flexRow alignCenter"} style={{ gap: "20px", cursor: "pointer" }}>
              <AiOutlineLink color="#000" />
              <CopyToClipboard
                text={urlShare}
                onCopy={() => {
                  showSuccess("Copied");
                  setLive(!showLiveMenu);
                }}
              >
                <Text tag={"p"} className={"f14 regularText"} style={{ color: "#000" }}>
                  Copy link
                </Text>
              </CopyToClipboard>
            </li>
          </ul>
        </div>
      )}
    </section>
  );
};

export default LiveMenuCard;
