import React, { useState } from "react";
import CustomInput from "../../../utils/CustomInput";
import Text from "../../../utils/CustomText";
import ProfileSetupLogo from "../../../assets/images/Logo.svg";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Button from "../../../utils/CustomButton";
import { useMediaQuery } from "react-responsive";
import CustomDropdown from "../../../components/auth/CustomBusinessDrop";
import http from "../../../Utils";
import { useNavigate } from "react-router-dom";
import { storeId } from "../../../Helpers";
import { useDispatch } from "react-redux";
import { setUpBusiness } from "../../../redux/actions/AuthActions";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { showError, showSuccess } from "../../../utils/Alert";

const ProfileSetup = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    business_name: "",
    business_email: "",
    // business_address: "",
    business_country_code: "234",
    business_phone: "",
    business_type: "",
    business_website: "",
    business_social_media_url1: "",
    business_social_media_url2: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [business_address, setBusinessAddress] = useState(null);

  const handleBusinessNameChange = (event) => {
    setState({
      ...state,
      business_name: event.target.value,
    });
  };

  const [isValid, setValid] = useState(true);
  const handleBusinessEmailChange = (event) => {
    const newEmail = event.target.value;
    setState({
      ...state,
      business_email: newEmail,
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(newEmail);
    setValid(isValidEmail);
  };

  // const handleBusinessAddressChange = (event) => {
  //   setState({
  //     ...state,
  //     business_address: event.target.value,
  //   });
  // };

  const handleBusinessPhoneChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, "");
    setState({
      ...state,
      business_phone: newValue,
    });
  };

  const handleBusinessWebsiteChange = (event) => {
    setState({
      ...state,
      business_website: event.target.value,
    });
  };

  const handleBusinessUrl1Change = (event) => {
    setState({
      ...state,
      business_social_media_url1: event.target.value,
    });
  };

  const handleBusinessUrl2Change = (event) => {
    setState({
      ...state,
      business_social_media_url2: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // console.log(file, "file");
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Set the result of the reader as the image source
        const imgSrc = e.target.result;
        document.getElementById("preview").src = imgSrc;
      };
      reader.readAsDataURL(file);
    }
  };

  const disabled = () => {
    const { business_address, business_country_code, business_email, business_name, business_phone, business_social_media_url1, business_social_media_url2, business_website } = state;
    if (business_address === "" || business_country_code === "" || business_email === "" || business_name === "" || business_phone === "" || business_social_media_url1 === "" || business_social_media_url2 === "" || business_website === "" || selectedFile === null || selectedOption === "") {
      return true;
    }
    return false;
  };

  const dispatch = useDispatch();
  const createBusiness = async () => {
    const { business_country_code, business_email, business_name, business_phone, business_social_media_url1, business_social_media_url2, business_type, business_website } = state;
    setLoading(true);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("business_name", business_name);
    formData.append("business_email", business_email);
    formData.append("business_address", business_address?.label);
    formData.append("business_country_code", business_country_code);
    formData.append("business_phone", business_phone);
    formData.append("business_type", selectedOption);
    formData.append("business_website", business_website);
    formData.append("business_social_media_url1", business_social_media_url1);
    formData.append("business_social_media_url2", business_social_media_url2);
    formData.append("business_logo", selectedFile);
    // console.log(formData, "formdata");

    try {
      const res = await dispatch(setUpBusiness(formData));
      console.log(res, "create business");
      setLoading(false);
      navigate("/dashboard", {});
      showSuccess("Profile Successfully Created");
    } catch (error) {
      console.log(error, "create business error");
      showError(error);
      setLoading(false);
    }
  };

  console.log(business_address?.label);
  return (
    <section className={"profileSetupContainer"}>
      <div className={"profileSetupLogo"}>
        <img src={ProfileSetupLogo} alt="logo" />
      </div>
      <div className={"profileSetupContent"}>
        <header className={"onBoardingHeader"}>
          <Text tag={"h2"} className={"textCenter"}>
            Business profile setup
          </Text>
          <Text tag={"p"} className={"textCenter"}>
            Tell us about your business
          </Text>
        </header>
        <div>
          <div className={"onBoardingInputGroup"}>
            <CustomInput label={"Name of business*"} onChange={handleBusinessNameChange} value={state.business_name} />
            {/* <CustomInput label={"Address*"} onChange={handleBusinessAddressChange} value={state.business_address} /> */}
            <div>
              <Text tag={"p"} className={"regularText f12"} style={{}}>
                Address
              </Text>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                selectProps={{
                  business_address,
                  onChange: setBusinessAddress,
                  styles: {
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #EBEBEB",
                      borderRadius: "8px",
                      outline: "none",
                      hover: "none",
                      padding: "0px 16px",
                      height: "40px",
                      color: "#000",
                      fontSize: "12px",
                    }),
                    option: (provided) => ({
                      ...provided,
                      color: "#000",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: " #999CA0",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                    }),
                  },
                  placeholder: "Enter address",
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["ng"],
                  },
                }}
              />
            </div>
          </div>
          <div className={"onBoardingInputGroup"}>
            <CustomInput label={"Phone number*"} type={"tel"} onChange={handleBusinessPhoneChange} value={state.business_phone} />
            <div>
              <CustomInput label={"Email address*"} type={"email"} onChange={handleBusinessEmailChange} value={state.business_email} />
              {!isValid && state.business_email !== "" && (
                <Text className={"f10"} style={{ color: "#F73838", top: "-15px", position: "relative" }}>
                  Enter a valid email
                </Text>
              )}
            </div>
          </div>
          <div className={"onBoardingInputGroup"}>
            <CustomDropdown selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
            <CustomInput label={"Website url*"} onChange={handleBusinessWebsiteChange} value={state.business_website} />
          </div>
          <div className={"onBoardingInputGroup"}>
            <CustomInput label={"Social media url (Twitter)*"} onChange={handleBusinessUrl1Change} value={state.business_social_media_url1} />
            <CustomInput label={"Social media url (Instagram)*"} onChange={handleBusinessUrl2Change} value={state.business_social_media_url2} />
          </div>
          <div className={"profileSetupUploadContainer"}>
            {selectedFile ? (
              <label>
                <div>
                  <img id="preview" src="" alt="Selected" style={{ height: "100px", width: "100px", borderRadius: "99px", objectFit: "cover" }} />
                  <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                </div>
              </label>
            ) : (
              <label>
                <div style={{ textAlign: "center" }}>
                  <AiOutlineCloudUpload />
                  <Text tag={"p"}>Click to Upload your company logo</Text>
                  <Text tag={"p"}>Maximum file size 10mb</Text>
                  <input type="file" onChange={handleFileChange} style={{ visibility: "hidden" }} />
                </div>
              </label>
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <Button text={`Create Account`} style={{ background: "#FF9000", border: "none", color: "#fff", marginTop: "58px", width: isDesktopOrLaptop ? "256px" : "100%", opacity: disabled() && "0.6" }} onClick={createBusiness} loading={loading} disabled={disabled()} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileSetup;
