import React from "react";
import Text from "../../../utils/CustomText";
import { AiOutlineClose } from "react-icons/ai";
import CustomInput from "../../../utils/CustomInput";
import Button from "../../../utils/CustomButton";

const AddCategoriesModal = ({ onDecline, onChangeCategory, updateCategories, category }) => {
  return (
    <section className={"modalBackground"}>
      <div className={"modalContent"}>
        <header className={"flexRow alignCenter justifyBetween"}>
          <Text tag={"h5"} className={"f18 semiBoldText"}>
            Add Category Name
          </Text>
          <AiOutlineClose color="#999CA0" onClick={onDecline} />
        </header>
        <div style={{ margin: "16px 0" }}>
          <CustomInput onChange={onChangeCategory} />
        </div>
        <div className={"flexRow alignCenter justifyEnd"}>
          <Button text={"Cancel"} className={"cancelBtn "} style={{ width: "67px", height: "32px" }} onClick={onDecline} />
          <Button text={"Save"} className={"saveBtn "} style={{ width: "62px", height: "32px" }} onClick={updateCategories} />
        </div>
      </div>
    </section>
  );
};

export default AddCategoriesModal;
