import React, { useEffect, useState } from "react";
import { TbPhotoEdit } from "react-icons/tb";
import { TfiLocationPin } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";
import { AiOutlineClose, AiOutlineInstagram, AiOutlinePlus } from "react-icons/ai";

import Text from "../../../utils/CustomText";
import Button from "../../../utils/CustomButton";
import AddCategoriesModal from "./AddCategoriesModal";
import useNavigatorOnLine from "../../network/useNavigatorOnline";
import AddFoodItemModal from "./AddFoodItemModal";
import http from "../../../Utils";
import { formatAmount, retrieveStoredId, shortenXterLength, storeBusiness } from "../../../Helpers";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleMenu } from "../../../redux/actions/MenuActions";
import EditFoodItemModal from "./EditFoodModal";
import { showError, showSuccess } from "../../../utils/Alert";

const Template1 = ({ logo, showControls, elementRef }) => {
  const [categories, setCategories] = useState([]); // Use state to manage categories
  const [food, setFood] = useState([]); // Use state to manage food items
  const location = useLocation();
  const data = location.state;
  console.log(data);
  const { singleMenu } = useSelector((state) => state.menu);
  const { businessProfile } = useSelector((state) => state.auth);
  // console.log(businessProfile, templateMenu, "profile");

  const [addCategoriesModal, setCatModalState] = useState(false);
  const [addFoodItemModal, setFoodItemModal] = useState(false);
  const [editFoodItemModal, setEditFoodItemModal] = useState(false);
  const [selectedFoodItem, setSelectedFoodItem] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [business, setBusiness] = useState(null);
  // console.log(categoryName);

  const openCatModal = () => {
    setCatModalState(!addCategoriesModal);
  };

  useEffect(() => {
    const businessId = data?.data?.businessMenu ? data?.data?.businessMenu?.businessId : data?.data?.businessId;
    const menuId = data?.data?.businessMenu ? data?.data?.businessMenu?.id : data?.data?.id;
    dispatch(getSingleMenu(businessId, menuId));
  }, []);

  const openFoodItemModal = () => {
    setFoodItemModal(!addFoodItemModal);
  };
  const onChangeCategory = (e) => {
    setCategoryName(e.target.value);
  };
  const updateCategories = () => {
    if (categoryName.trim() !== "") {
      // Only add non-empty categories
      setCategories([...categories, categoryName]);
    }
    setCategoryName(""); // Clear the input field
    openCatModal();
  };
  const deleteCategoriesItem = (index) => {
    const newArray = [...categories];
    newArray.splice(index, 1);
    setCategories(newArray); // Update the categories state
  };

  const dispatch = useDispatch();
  const deleteItem = async (itemId) => {
    const businessId = singleMenu?.businessMenu?.businessId;
    const menuId = singleMenu?.businessMenu?.id;
    try {
      const res = await http.delete(`business/${businessId}/menus/${menuId}/items/${itemId}`);
      showSuccess("Item deleted");
      if (res) {
        dispatch(getSingleMenu(businessId, menuId));
      }
      // console.log(res, "item deleted");
    } catch (error) {
      // console.log(error);
      showError(error);
    }
  };

  const editFoodItem = (val) => {
    setEditFoodItemModal(true);
    setSelectedFoodItem(val);
  };
  const closeEditModal = () => {
    setEditFoodItemModal(false);
  };

  const openInGoogleMaps = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(businessProfile?.business?.businessAddress)}`;
    window.open(mapsUrl, "_blank");
  };

  const callNumber = () => {
    window.location.href = `tel:${businessProfile?.business?.businessPhone[0]}`;
  };

  const openInstagram = () => {
    window.open(`https://www.instagram.com/${businessProfile?.business?.businessSocialMediaUrl[1]}`, "_blank");
  };

  const openTwitter = () => {
    window.open(`https://twitter.com/${businessProfile?.business?.businessSocialMediaUrl[1]}`, "_blank");
  };

  return (
    <>
      <section className={"templateContainer"} ref={elementRef}>
        <header className={"template1Header"}>
          <div className={"menuLogoContainer"}>
            {businessProfile?.business?.businessLogo ? (
              <img src={businessProfile?.business?.businessLogo} alt="menuLogo" style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: "99px" }} />
            ) : (
              <>
                <TbPhotoEdit />
                <Text tag={"p"} className={"f14 boldText"}>
                  Logo
                </Text>
              </>
            )}
          </div>
          <div className={"flexRow alignCenter"}>
            <TfiLocationPin />
            <Text tag={"p"} className={"f14 semiBoldText capitalize"}>
              {shortenXterLength(businessProfile?.business?.businessAddress, 30)}
            </Text>
          </div>
          <div className={"flexRow alignCenter"}>
            <BsTelephone />
            <Text tag={"p"} className={"f14 semiBoldText"}>
              +{businessProfile?.business?.businessPhone[0]}
            </Text>
          </div>
          <div className={"flexRow alignCenter"}>
            <AiOutlineInstagram size={22} onClick={openInstagram} />
            <FiTwitter size={22} onClick={openTwitter} />
          </div>
        </header>
        <div className={"templateContent"}>
          <div className={"templateFoodContainer"}>
            {showControls && (
              <button className={"addCategoriesButton flexRow alignCenter justifyCenter"} onClick={openFoodItemModal} style={{ cursor: "pointer" }}>
                <AiOutlinePlus />
                <Text tag={"p"} className={"f12 regularText"}>
                  Add food item
                </Text>
              </button>
            )}
            <ul>
              {singleMenu &&
                singleMenu?.businessMenu?.menuItems?.map((item, index) => {
                  return (
                    <li className={"foodItemCardTypeOne"} key={index}>
                      <div className={"flexRow alignCenter"} style={{ gap: "16px" }}>
                        <img src={item?.imageUrls[0]} style={{ width: "80px", height: "80px" }} />
                        <div style={{ paddingRight: "20px" }}>
                          <Text tag={"p"} className={"boldText f16"} style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                            {item?.name}
                          </Text>
                          <Text tag={"p"} className={"regularText f14"}>
                            {item?.description}
                          </Text>
                        </div>
                      </div>
                      <div className={"foodItemCardTypeOnePrice"}>
                        <Text tag={"p"} className={"f14 mediumText"}>
                          NGN{formatAmount(item?.price)}
                        </Text>
                      </div>
                      {showControls && (
                        <div className={"flexRow alignCenter"}>
                          <Button
                            text={"Delete"}
                            className={"deleteBtn"}
                            onClick={() => {
                              deleteItem(item?.id);
                            }}
                          />
                          <Button
                            text={"Edit"}
                            className={"editBtn"}
                            onClick={() => {
                              editFoodItem(item);
                            }}
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </section>
      {addCategoriesModal && <AddCategoriesModal onDecline={openCatModal} onChangeCategory={onChangeCategory} updateCategories={updateCategories} />}
      {addFoodItemModal && <AddFoodItemModal onDecline={openFoodItemModal} food={food} setFood={setFood} openFoodItemModal={openFoodItemModal} business={businessProfile} data={data} />}
      {editFoodItemModal && <EditFoodItemModal onDecline={closeEditModal} business={businessProfile} data={selectedFoodItem} />}
    </>
  );
};

export default Template1;
