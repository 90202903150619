import React, { useEffect } from "react";
import UserInfoCard from "../../components/dashboard/account/UserInfoCard";
import Logo from "../../assets/images/Logo.svg";
import UserOverview from "../../components/dashboard/account/UserOverview";
import UserTransactions from "../../components/dashboard/account/UserTransactions";
import { useDispatch, useSelector } from "react-redux";
import { retrieveStoredId } from "../../Helpers";
import { getBusinessProfile } from "../../redux/actions/BusinessActions";

const data = {
  src: Logo,
  name: "Chiomy Foods",
  phone: "09089890978",
  address: "No 14 B, Independence Layout, Enugu, Nigeria",
  email: "chiomyfoods@gmail.com",
};
const Account = () => {
  return (
    <main className={"accountsContainer"}>
      <UserInfoCard />
      <UserOverview />
      {/* <UserTransactions />  */}
    </main>
  );
};

export default Account;
