import React, { useEffect } from "react";
import { TbPhotoEdit } from "react-icons/tb";
import Text from "../../utils/CustomText";
import { TfiLocationPin } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FiTwitter } from "react-icons/fi";
import { formatAmount, shortenXterLength } from "../../Helpers";
import { getSingleLiveMenu, getSingleMenu } from "../../redux/actions/MenuActions";
import { getBusinessProfile } from "../../redux/actions/BusinessActions";

const DisplayTemplate = () => {
  const { templateMenu } = useSelector((state) => state.menu);
  const { businessProfile } = useSelector((state) => state.auth);
  console.log(businessProfile, "fjfnvjn");
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split("/");

    // Extract the business and menu IDs
    const businessId = pathParts[2];
    const menuId = pathParts[4];
    console.log(businessId, menuId, "ids");

    // console.log("Business ID:", businessId);
    // console.log("Menu ID:", menuId);
    if (businessId && menuId) {
      dispatch(getSingleLiveMenu(businessId, menuId));
      dispatch(getBusinessProfile(businessId));
    }
  }, []);
  const openInGoogleMaps = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(templateMenu?.businessMenu?.address)}`;
    window.open(mapsUrl, "_blank");
  };

  const callNumber = () => {
    window.location.href = `tel:${templateMenu?.businessMenu?.phoneNumber[0]}`;
  };

  const openInstagram = () => {
    window.open(`https://www.instagram.com/${templateMenu?.businessMenu?.businessSocialMediaUrl[1]}`, "_blank");
  };

  const openTwitter = () => {
    window.open(`https://twitter.com/${templateMenu?.businessMenu?.businessSocialMediaUrl[1]}`, "_blank");
  };

  return (
    <section className={"templateContainer"}>
      <header className={"template1Header"}>
        <div className={"menuLogoContainer"}>
          {templateMenu?.businessMenu?.menuLogo ? (
            <img src={templateMenu?.businessMenu?.menuLogo} alt="menuLogo" style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: "99px" }} />
          ) : (
            <>
              <TbPhotoEdit />
              <Text tag={"p"} className={"f14 boldText"}>
                Logo
              </Text>
            </>
          )}
        </div>
        <div className={"flexRow alignCenter"}>
          <TfiLocationPin />
          <Text tag={"p"} className={"f14 semiBoldText capitalize"} onClick={openInGoogleMaps}>
            {shortenXterLength(templateMenu?.businessMenu?.address, 30)}
          </Text>
        </div>
        <div className={"flexRow alignCenter"}>
          <BsTelephone />
          <Text tag={"p"} className={"f14 semiBoldText"} onClick={callNumber}>
            + {templateMenu?.businessMenu?.phoneNumber[0]}
          </Text>
        </div>
        <div className={"flexRow alignCenter"}>
          <AiOutlineInstagram size={22} onClick={openInstagram} />
          <FiTwitter size={22} onClick={openTwitter} />
        </div>
      </header>
      <div className={"templateContent"}>
        <div className={"templateFoodContainer"}>
          <ul>
            {templateMenu &&
              templateMenu?.businessMenu?.menuItems?.map((item, index) => {
                return (
                  <li className={"foodItemCardTypeOne"} key={index}>
                    <div className={"flexRow alignCenter"} style={{ gap: "16px" }}>
                      <img src={item?.imageUrls[0]} style={{ width: "80px", height: "80px", objectFit: "cover" }} />
                      <div style={{ paddingRight: "20px" }}>
                        <Text tag={"p"} className={"boldText f16"} style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                          {item?.name}
                        </Text>
                        <Text tag={"p"} className={"regularText f14"}>
                          {item?.description}
                        </Text>
                      </div>
                    </div>
                    <div className={"foodItemCardTypeOnePrice"}>
                      <Text tag={"p"} className={"f14 mediumText"}>
                        NGN{formatAmount(item?.price)}
                      </Text>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DisplayTemplate;
