import React from "react";
import Text from "../../../utils/CustomText";

const TemplatesCard = ({ src, onClick }) => {
  return (
    <section className={"templatesCardContainer"} onClick={onClick} style={{ cursor: "pointer" }}>
      <img src={src} alt="template" />
      <div className={"freeTag"}>
        <Text tag={"p"} className={"f12 boldText"} style={{ color: "#fff" }}>
          Free
        </Text>
      </div>
    </section>
  );
};

export default TemplatesCard;
