import React, { useState } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Templates from "./Templates";
import TopNav from "../../components/dashboard/TopNav";
import { SideBar } from "../../components/dashboard/SideBar";
import Menu from "./Menu";
import Account from "./Account";
import Settings from "./Settings";
import MenuMaker from "./MenuMaker";
import NotFound from "./NotFound";
import MenuPreview from "./MenuPreview";
import MenuQRCode from "./MenuQRCode";
import { useDispatch } from "react-redux";
import { SIGNOUT_USER_SUCCESSFUL } from "../../redux/Types";
import LogoutModal from "../../components/dashboard/templates/LogoutModal";

const DashboardLayout = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const toggleSideBar = () => {
    setShowSideBar(!showSideBar);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = () => {
    dispatch({ type: SIGNOUT_USER_SUCCESSFUL });
    // localStorage.clear();
    navigate("/");
  };

  const [showLogOut, setShowLogOut] = useState(false);
  const toggleLogOut = () => {
    setShowLogOut(!showLogOut);
  };

  return (
    <>
      <main className={"dashboardLayoutContainer"}>
        <section className={"dashboardLayoutSideBar"}>
          <SideBar showSideBar={showSideBar} toggleSideBar={toggleSideBar} toggleLogOut={toggleLogOut} />
        </section>
        <section className={"dashboardLayoutContentContainer"}>
          <TopNav toggleSideBar={toggleSideBar} />
          <Routes>
            <Route path="/" element={<Templates />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/menuMaker/:name" element={<MenuMaker />} />
            <Route path="/menuQRCode" element={<MenuQRCode />} />
            <Route path="/menuPreview" element={<MenuPreview />} />
            <Route path="/account" element={<Account />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Outlet />
        </section>
      </main>
      {/* <main className={"dashLay"}>
        <div className={"dashLeft"}></div>
        <div className={"dashRight"}></div>
      </main> */}
      {showLogOut && <LogoutModal logOut={logOut} toggleLogOut={toggleLogOut} />}
    </>
  );
};

export default DashboardLayout;
