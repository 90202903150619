import React from 'react';
import Text from './CustomText';

const Button = ({ text, onClick, className, loading, disabled, img, ...otherProps }) => {
    const combinedClassName = `customButton text semiBoldText f16 ${className}`.trim(); // Combine the general className and the passed className

    return (
        <button className={combinedClassName} onClick={onClick} {...otherProps} disabled={disabled} >
            {!loading ? <Text>{text}</Text> : ''}
            {img && <img src={img} alt={'icon'} />}
            {loading && <span className={'loader'}></span>}
        </button>
    );
};

export default Button;