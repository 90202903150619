export const LOADING_USER = "loading_user";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAIL = "login_fail";

export const GETTING_OTP = 'getting_otp';
export const GETTING_OTP_SUCCESSFUL = 'getting_otp_successful';
export const GETTING_OTP_FAIL = 'getting_otp_fail';

export const VERIFY_OTP = 'verify_otp';
export const VERIFY_OTP_SUCCESSFUL = 'verify_otp_successful';
export const VERIFY_OTP_FAIL = 'verify_otp_fail';

export const AUTO_LOGIN_DETAILS = 'auto_login_details'

export const RESET_PASSWORD = 'reset_password';
export const RESET_PASSWORD_SUCCESSFUL = 'reset_password_successful';
export const RESET_PASSWORD_FAIL = 'reset_password_fail';


export const CREATE_BUSINESS = 'create_business';
export const CREATE_BUSINESS_SUCCESSFUL = 'create_business_successful';
export const CREATE_BUSINESS_FAIL = 'create_business_fail';



export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESSFUL = 'signout_user_successful';
export const SIGNOUT_USER_FAIL = 'signout_user_fail';


export const SETUP_BUSINESS = 'setup_business';
export const SETUP_BUSINESS_SUCCESSFUL = 'setup_business_successful';
export const SETUP_BUSINESS_FAIL = 'setup_business_fail';


export const UPDATE_MENU = 'update_menu';

export const GETTING_MENU = 'getting_menu';
export const GETTING_MENU_SUCCESSFUL = 'getting_menu_successful';
export const GETTING_MENU_FAIL = 'getting_menu_fail';



export const GETTING_SINGLE_MENU = 'getting_single_menu';
export const GETTING_SINGLE_MENU_SUCCESSFUL = 'getting_single_menu_successful';
export const GETTING_SINGLE_MENU_FAIL = 'getting_single_menu_fail';


export const GETTING_BUSINESS_PROFILE = 'getting_business_profile';
export const GETTING_BUSINESS_PROFILE_SUCCESSFUL = 'getting_business_profile_successful';
export const GETTING_BUSINESS_PROFILE_FAIL = 'getting_business_profile_fail';



