import React, { useState } from "react";
import { AiOutlineClose, AiOutlinePlus, AiOutlinePlusSquare } from "react-icons/ai";
import Button from "../../../utils/CustomButton";
import Text from "../../../utils/CustomText";
import CustomInput from "../../../utils/CustomInput";
import { TbPhotoEdit } from "react-icons/tb";
import http from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { getSingleMenu } from "../../../redux/actions/MenuActions";
import { useMediaQuery } from "react-responsive";
import { shortenXterLength } from "../../../Helpers";
import { BsCaretDown, BsCaretDownFill } from "react-icons/bs";
import { showError, showSuccess } from "../../../utils/Alert";

const AddFoodItemModal = ({ food, setFood, openFoodItemModal, business, data }) => {
  const [state, setState] = useState({
    price: "",
    imageUrl: "",
    description: "",
    name: "",
  });
  const { businessProfile } = useSelector((state) => state.auth);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [selectedFile, setSelectedFile] = useState("");
  // console.log(data?.data, "d");

  const onChangeName = (e) => {
    setState({ ...state, name: e.target.value });
  };
  const onChangeDescription = (e) => {
    setState({ ...state, description: e.target.value });
  };
  const onChangePrice = (e) => {
    setState({ ...state, price: e.target.value });
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  //   console.log(file, "file");
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       // Set the result of the reader as the image source
  //       const imgSrc = e.target.result;
  //       document.getElementById("preview").src = imgSrc;
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const [imageLoading, setImageLoading] = useState(false);

  const handleFileChange = async (event) => {
    // const { product_images } = state;
    const formData = new FormData();
    formData.append("business_image", event.target.files[0]);
    setImageLoading(true);
    try {
      const res = await http.post("business/upload_image", formData);
      // console.log(res);
      if (res) {
        // product_images.push();
        setImageLoading(false);
        setSelectedFile(res?.data?.imageUrl);
      }
    } catch (error) {
      // console.log(error);
      setImageLoading(false);
      showError(error);
    }
  };

  const addFoodItem = () => {
    const { price, imageUrl, description, name } = state;
    // console.log(state);
    if (price !== "" && selectedFile !== "" && description !== "" && name !== "") {
      setFood([...food, { name, imageUrl: selectedFile, description, price: Number(price) }]);
      // Clear the form fields after adding the food item
      // console.log(food);
      showSuccess("Food Item Added");
      setState((prevState) => ({ ...prevState, description: "", price: "", imageUrl: "", name: "" }));
      setSelectedFile("");
    }
  };
  // console.log(food, "food");

  const deleteFoodItem = (index) => {
    const newArray = [...food];
    newArray.splice(index, 1);
    setFood(newArray); // Update the food statme
  };

  const dispatch = useDispatch();

  const saveMenu = async () => {
    const menu_items = food;
    const obj = { menu_items };
    const businessId = businessProfile?.business?.id;
    const menuId = data?.data?.businessMenu?.id ? data?.data?.businessMenu?.id : data?.data?.id;
    console.log(menu_items, menuId, businessId, "people");
    try {
      const res = await http.post(`business/${businessId}/menus/${menuId}/items`, obj);

      if (res) {
        setFood([]);
        openFoodItemModal();
        dispatch(getSingleMenu(businessId, menuId));
        setState({
          name: "",
          description: "",
          price: "",
        });
        setSelectedFile("");
        showSuccess("Menu Updated!");
      }

      // console.log(res);
    } catch (error) {
      // console.log(error);
      showError(error);
    }
  };

  return (
    <section className={"modalBackground"}>
      <div className={"foodItemModalContent"}>
        <header className={"flexRow alignCenter justifyBetween"}>
          <div className="flexRow alignCenter">
            <Text tag={"p"} className={"f20 boldText"}>
              Add item
            </Text>
            {food?.length !== 0 && (
              <div className={"foodItemsList flexRow alignCenter justifyBetween"}>
                <div className="foodCounter">
                  <Text tag={"h4"} className={"mediumText f14"} style={{ color: "#fff" }}>
                    {food?.length}
                  </Text>
                </div>
                <Text tag={"h4"} className={"mediumText f14"} style={{ color: "#000" }}>
                  Items added
                </Text>
              </div>
            )}
          </div>
          <AiOutlineClose color="#000" onClick={openFoodItemModal} className="boldText" />
        </header>
        {/* <Button text={"Save"} className={"saveBtn "} style={{ width: "62px", height: "32px" }} onClick={saveMenu} /> */}

        <div className={"foodItemFormContainer"}>
          <Text className={"boldText f16"} style={{ color: "#919294" }}>
            Item information
          </Text>
          <div className={"foodItemForm"}>
            <div className={`flexRow alignCenter  ${isDesktopOrLaptop ? "noWrap" : "flexWrap"}`} style={{ gap: "24px" }}>
              <div style={{ width: isDesktopOrLaptop ? "60%" : "100%" }}>
                <CustomInput label={"Food item"} customInput={"bgWhite"} onChange={onChangeName} value={state.name} />
              </div>
              <div className={"flexRow alignCenter"} style={{ gap: "7px" }}>
                <div className={"w-10"}>
                  <CustomInput label={"Price"} customInput={"bgWhite"} defaultValue={"NGN"} readOnly={true} inputStyle={"f10"} />
                </div>
                <div className={"w-50"}>
                  <CustomInput label={"."} customInput={"bgWhite"} onChange={onChangePrice} value={state.price} />
                </div>
              </div>
            </div>
            <CustomInput label={"Description"} customInput={"bgWhite"} onChange={onChangeDescription} value={state.description} />

            <div>
              <Text tag={"p"} className={"f14 mediumText"} style={{ color: "#000" }}>
                Food photo
              </Text>
              <div className={"foodItemImageUpload"}>
                {selectedFile ? (
                  <label>
                    {imageLoading ? (
                      <span className="loader" style={{ border: "3px solid #ff9000" }}></span>
                    ) : (
                      <div>
                        <img id="preview" src={selectedFile} alt="Selected" style={{ height: "40px", width: "40px", borderRadius: "5px", objectFit: "cover" }} />
                        <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                      </div>
                    )}
                  </label>
                ) : (
                  <label>
                    {imageLoading ? (
                      <span className="loader" style={{ border: "3px solid #ff9000" }}></span>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <TbPhotoEdit color="rgba(145, 146, 148, 1)" size={25} />
                        <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                        <Text style={{ color: "#000", position: "relative", top: "-7px", marginLeft: "10px" }} className={"f12"}>
                          Browse & upload .png, jpeg or gif
                        </Text>
                      </div>
                    )}
                  </label>
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                <Button text={"Add food item"} className={"saveBtn "} onClick={addFoodItem} />
              </div>
            </div>
          </div>
        </div>
        <div className="foodItemModalFooter" style={{ textAlign: "right" }}>
          <div>
            <Button text={"Cancel"} className={""} style={{ width: "88px", height: "32px", border: "1px solid #999CA0", background: "white" }} onClick={openFoodItemModal} />
          </div>
          <div>
            <Button text={"Create"} className={"saveBtn "} style={{ width: "99px", height: "32px", background: "#FFA024", color: "#fff", border: "none" }} onClick={saveMenu} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddFoodItemModal;
