import http from "../../Utils";
import { GETTING_BUSINESS_PROFILE, GETTING_BUSINESS_PROFILE_FAIL, GETTING_BUSINESS_PROFILE_SUCCESSFUL } from "../Types";

export const getBusinessProfile = (businessId) => {
    return dispatch => {
        dispatch({ type: GETTING_BUSINESS_PROFILE })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`business/${businessId}`)
                const data = res?.data
                console.log('business', res)
                dispatch({ type: GETTING_BUSINESS_PROFILE_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                console.log('business', error)
                dispatch({ type: GETTING_BUSINESS_PROFILE_FAIL, payload: error });
                reject(error);
            }
        });
    };
};
