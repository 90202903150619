import React, { useEffect } from "react";
import Text from "../../../utils/CustomText";
import { BiFile } from "react-icons/bi";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getAllMenu } from "../../../redux/actions/MenuActions";

const UserOverview = () => {
  const { menus } = useSelector((state) => state.menu);
  const { businessProfile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (businessProfile) {
      dispatch(getAllMenu(businessProfile?.business?.id));
    }
  }, []);

  const totalPublishedMenus = menus?.data?.businessMenus?.filter((menu) => menu.isPublished).length;

  return (
    <section className={"userOverviewContainer"}>
      <Text className={"semiBoldText f20"} tag={"p"} style={{ padding: "0 0 30px 0" }}>
        Overview
      </Text>
      <div className={"flexRow alignCenter flexWrap"} style={{ gap: "30px 105px" }}>
        <div className={"userOverviewCard"}>
          <ul className={"flexRow alignCenter justifyBetween"}>
            <li className={"flexRow alignCenter"}>
              <div>
                <BiFile />
              </div>
              <Text tag={"p"} className={"boldText f20"}>
                Created Menu
              </Text>
            </li>
            <li>
              <Text tag={"p"} className={"semiBoldText f20"}>
                {menus ? menus?.data?.businessMenus?.length : 0}
              </Text>
            </li>
          </ul>
        </div>
        <div className={"userOverviewCard"}>
          <ul className={"flexRow alignCenter justifyBetween"}>
            <li className={"flexRow alignCenter"}>
              <div>
                <BsFillCheckCircleFill />
              </div>
              <Text tag={"p"} className={"boldText f20"}>
                Live Menu
              </Text>
            </li>
            <li>
              <Text tag={"p"} className={"semiBoldText f20"}>
                {totalPublishedMenus}
              </Text>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default UserOverview;
