import React from 'react'
import Text from '../../utils/CustomText'
import { useMediaQuery } from 'react-responsive'
import KeyFeaturesCard from './KeyFeaturesCard'
import MaasLocation from '../../assets/images/massLocation.png'
import MaasMenu from '../../assets/images/massMenu.png'
import MaasQR from '../../assets/images/massQR.png'


const KeyFeaturesSection = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    return (
        <section className={'keyFeaturesContainer textCenter'}>

            <Text
                tag="h2" className={isDesktopOrLaptop ? 'f52 boldText pb24' : 'f38 boldText pb24'}
                style={{ color: '#000' }}
            >
                Key Features
            </Text>

            <div className={'keyFeaturesGrid'}>
                <KeyFeaturesCard title={'Create custom menus'} description={'Be your own menu designer. With easy to use templates, design your virtual menus how you want them.'}
                    src={MaasMenu}
                />
                <KeyFeaturesCard title={'Get QR codes for your menus'} description={'When you create your virtual menus, you get QR codes that can be used online and in your physical stores.'}
                    src={MaasQR}
                />
                <KeyFeaturesCard title={'Assessible from any location'} description={'Customers can assess your virtual menus and place orders from any location.'}
                    src={MaasLocation}
                />
            </div>
        </section>
    )
}

export default KeyFeaturesSection
