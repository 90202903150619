import { setToken } from "../../Helpers";
import http from "../../Utils";
import { GETTING_OTP, GETTING_OTP_FAIL, GETTING_OTP_SUCCESSFUL, LOADING_USER, LOGIN_FAIL, LOGIN_SUCCESS, SETUP_BUSINESS, SETUP_BUSINESS_FAIL, SETUP_BUSINESS_SUCCESSFUL, VERIFY_OTP, VERIFY_OTP_FAIL, VERIFY_OTP_SUCCESSFUL } from "../Types";

export const loginReq = (obj) => {
    return dispatch => {
        dispatch({ type: LOADING_USER })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("auth/sign_in", obj)
                const data = res?.data
                console.log('login ', res)
                dispatch({ type: LOGIN_SUCCESS, payload: data });
                setToken(data)
                resolve(data);
            } catch (error) {
                console.log('login', error)
                dispatch({ type: LOGIN_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const signUpReq = (obj) => {
    return dispatch => {
        dispatch({ type: GETTING_OTP })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("auth/sign_up", obj)
                const data = res
                console.log('sign up ', res)
                dispatch({ type: GETTING_OTP_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                console.log('sign up', error)
                dispatch({ type: GETTING_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


// auth / customer / profile

export const verifyOtp = (obj) => {
    return dispatch => {
        dispatch({ type: VERIFY_OTP })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("auth/sign_up/verify", obj)
                const data = res.data
                setToken(data)
                dispatch({ type: VERIFY_OTP_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: VERIFY_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };

};

// /auth/reset_password / send_otp

export const forgotPasswordOtpReq = (obj) => {
    return dispatch => {
        dispatch({ type: GETTING_OTP })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("auth/reset_password/send_otp", obj)
                const data = res
                console.log('forgot password otp req ', res)
                dispatch({ type: GETTING_OTP_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                console.log('forgot password otp req', error)
                dispatch({ type: GETTING_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

// auth/reset_password/new_password



// auth/setup business

export const setUpBusiness = (obj) => {
    return dispatch => {
        dispatch({ type: SETUP_BUSINESS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("business/create", obj)
                const data = res?.data
                console.log('setup business ', res)
                dispatch({ type: SETUP_BUSINESS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                console.log('setup business', error)
                dispatch({ type: SETUP_BUSINESS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



