import React from "react";

const TemplatesSideBarCard = ({ src, background, border, onClick }) => {
  return (
    <section className={"templatesSideCardContainer"} style={{ background: background, border: border, cursor: "pointer" }} onClick={onClick}>
      <img src={src} alt="template" />
    </section>
  );
};

export default TemplatesSideBarCard;
