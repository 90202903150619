import React, { useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import Button from "../../../utils/CustomButton";
import Text from "../../../utils/CustomText";
import CustomInput from "../../../utils/CustomInput";
import { TbPhotoEdit } from "react-icons/tb";
import http from "../../../Utils";
import { useDispatch } from "react-redux";
import { getSingleMenu } from "../../../redux/actions/MenuActions";

const EditFoodItemModal = ({ food, setFood, openFoodItemModal, business, data, onDecline }) => {
  const [state, setState] = useState({
    price: "",
    imageUrl: "",
    description: "",
    name: "",
    loading: false,
  });

  const [selectedFile, setSelectedFile] = useState("");
  console.log(data, "l");

  const onChangeName = (e) => {
    setState({ ...state, name: e.target.value });
  };
  const onChangeDescription = (e) => {
    setState({ ...state, description: e.target.value });
  };
  const onChangePrice = (e) => {
    setState({ ...state, price: e.target.value });
  };

  const handleFileChange = async (event) => {
    // const { product_images } = state;
    const formData = new FormData();
    formData.append("business_image", event.target.files[0]);
    try {
      const res = await http.post("business/upload_image", formData);
      console.log(res);
      if (res) {
        // product_images.push();
        setSelectedFile(res?.data?.imageUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();

  const saveMenu = async () => {
    const foodName = state.name ? state?.name : data?.name;
    const foodDescription = state.description ? state?.description : data?.name;
    const foodPrice = state.price ? Number(state?.price) : data?.price;
    const foodImage = selectedFile ? selectedFile : data?.imageUrls[0];

    const obj = {
      item_name: foodName,
      item_image_url: foodImage,
      item_price: foodPrice,
      item_description: foodDescription,
    };

    const businessId = business?.business?.id;
    const menuId = data?.businessMenuId;
    console.log(businessId, menuId, obj, data?.id, "us");
    setState({ ...state, loading: true });
    try {
      const res = await http.put(`business/${businessId}/menus/${menuId}/items/${data?.id}`, obj);
      setState({ ...state, loading: false });

      if (res) {
        dispatch(getSingleMenu(businessId, menuId));
        setState({
          name: "",
          description: "",
          price: "",
        });
        setSelectedFile("");
        onDecline();
      }

      console.log(res, "res");
    } catch (error) {
      console.log(error);
      setState({ ...state, loading: false });
    }
  };

  return (
    <section className={"modalBackground"}>
      <div className={"foodItemModalContent"}>
        <header className={"flexRow alignCenter justifyBetween"}>
          <AiOutlineClose color="#fff" onClick={onDecline} />
          <Button text={"Update"} className={"saveBtn "} style={{ width: "70px", height: "32px" }} onClick={saveMenu} loading={state.loading} />
        </header>
        <div className={"foodItemFormContainer"}>
          <div style={{ padding: "10px 0" }}>
            <Text className={"boldText f18"} style={{ color: "#000" }}>
              Edit Food Item
            </Text>
          </div>
          <div className={"foodItemForm"}>
            <CustomInput label={"Food item"} customInput={"bgWhite"} onChange={onChangeName} placeholder={data?.name} />
            <CustomInput label={"Description"} customInput={"bgWhite"} onChange={onChangeDescription} placeholder={data?.description} />
            <div className={"flexRow alignCenter"} style={{ gap: "20px" }}>
              <div className={"w-10"}>
                <CustomInput label={"Price"} customInput={"bgWhite"} defaultValue={"NGN"} />
              </div>
              <div className={"w-50"}>
                <CustomInput label={"."} customInput={"bgWhite"} onChange={onChangePrice} placeholder={data?.price} />
              </div>
            </div>
            <div>
              <Text tag={"p"} className={"f14 mediumText"} style={{ color: "#000" }}>
                Food photo
              </Text>
              <div className={"foodItemImageUpload"}>
                {selectedFile ? (
                  <label>
                    <div>
                      <img id="preview" src={selectedFile} alt="Selected" style={{ height: "40px", width: "40px", borderRadius: "5px", objectFit: "cover" }} />
                      <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                    </div>
                  </label>
                ) : (
                  <label>
                    <div style={{ textAlign: "center" }}>
                      <img id="preview" src={data?.imageUrls[0]} alt="Selected" style={{ height: "40px", width: "40px", borderRadius: "5px", objectFit: "cover" }} />
                      <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                    </div>
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditFoodItemModal;
