import React from "react";
import Text from "../../utils/CustomText";
import { useMediaQuery } from "react-responsive";

const OnboardingCards = ({ title, subTitle, img }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <section className={"onBoardingCardContainer"}>
      <div className="onboardingCardImage">
        <img src={img} alt="onboardingImage" />
      </div>
      <div className={"onboardingCardText"}>
        <Text tag={"p"} className={`boldText ${isDesktopOrLaptop ? "f20" : "f18"}`} style={{ color: "rgba(0, 0, 0, 1)" }}>
          {title}
        </Text>
        <Text tag={"p"} className={`regularText ${isDesktopOrLaptop ? "f18" : "f14"}`} style={{ color: "rgba(105, 106, 107, 1)" }}>
          {subTitle}
        </Text>
      </div>
    </section>
  );
};

export default OnboardingCards;
