import React from 'react'
import Button from '../../utils/CustomButton'
import Text from '../../utils/CustomText'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


const HeroSection = ({ openAuth }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const { loggedIn } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const goToDashboard = () => {
        navigate('/dashboard')
    }


    return (
        <section className={'heroContainer'}>
            <div style={{ width: isDesktopOrLaptop ? '848px' : '100%' }}>
                <Text tag="h2" className={isDesktopOrLaptop ? 'f64 boldText pb24' : 'f38 boldText pb24'}
                    style={{ color: '#F9F9FB' }}
                >
                    Create custom virtual menus for your business.
                </Text>
                <Text tag="p" className={isDesktopOrLaptop ? 'f30 pb40' : 'f20 pb40'}
                    style={{ color: '#E6E7E9' }}
                >
                    Let your customer know what is available in real-time when you virtually create and share your menus with them.
                </Text>
            </div>
            {loggedIn ?
                <Button
                    text={'Get Started'}
                    style={{ background: '#FFA024', border: 'none', color: '#fff', maxWidth: "169px" }}
                    onClick={goToDashboard}
                /> : <Button
                    text={'Get Started'}
                    style={{ background: '#FFA024', border: 'none', color: '#fff', maxWidth: "169px" }}
                    onClick={openAuth}
                />}
        </section>
    )
}

export default HeroSection