import React, { useEffect, useRef, useState } from "react";
import Text from "../../utils/CustomText";
import Button from "../../utils/CustomButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllMenu } from "../../redux/actions/MenuActions";
import { retrieveStoredId } from "../../Helpers";
import MenuCard from "../../components/dashboard/menu/MenuCard";
import { useMediaQuery } from "react-responsive";
import LiveMenuCard from "../../components/dashboard/menu/LiveMenuCard";

const Menu = () => {
  const navigate = useNavigate();
  const goToTemplates = () => {
    navigate("/dashboard");
  };
  const { menus } = useSelector((state) => state.menu);
  const { businessProfile } = useSelector((state) => state.auth);
  // console.log(menus, "menu");
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // Call the retrieveStoredId function here

    if (businessProfile) {
      dispatch(getAllMenu(businessProfile?.business?.id));
    }
  }, []);

  const dropdownRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // console.log("Event Target:", event.target);
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setShowMenu(false);
  //     }
  //   };

  //   // Attach the event listener to the document
  //   document.addEventListener("mousedown", handleClickOutside);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const renderEmptyState = () => {
    if (menus && menus?.data?.businessMenus?.length === 0) {
      return (
        <section className={"flexColumn justifyCenter alignCenter"}>
          <Text tag={"h3"} className={"f38 boldText greyText textCenter"} style={{ width: "600px" }}>
            Oops! Nothing here. You haven’t created a menu yet.
          </Text>
          <Text tag={"p"} className={"f20 regularText blackText"}>
            Click on the button below to begin.
          </Text>
          <Button
            text={"Get Started"}
            style={{
              width: "105px",
              background: "#FFA024",
              border: "none",
              height: "32px",
              color: "#Fff",
              fontSize: "14px",
              margin: "20px 0",
            }}
            onClick={goToTemplates}
          />
        </section>
      );
    }
  };

  const renderLiveMenu = () => {
    if (menus && menus?.data?.businessMenus?.length !== 0) {
      return (
        <section>
          <header style={{ padding: "20px 0" }}>
            <Text tag={"h2"} className={`f24 boldText`} style={{ color: "#000" }}>
              Live Menus
            </Text>
            <Text tag={"p"} className={`f14 regularText`} style={{ color: "rgba(177, 178, 180, 1)" }}>
              Live menus are open to the public and currently taking orders.
            </Text>
          </header>
          <div className={"menuGrid"}>
            {menus?.data?.businessMenus?.map((item, index) => {
              return <LiveMenuCard item={item} index={index} />;
            })}
          </div>
        </section>
      );
    }
  };
  const renderUnpublishedMenu = () => {
    if (menus && menus?.data?.businessMenus?.length !== 0) {
      return (
        <section style={{ margin: isDesktopOrLaptop ? "40px 0" : "20px 0" }}>
          <header style={{ padding: "20px 0" }}>
            <Text tag={"h2"} className={`f24 boldText`} style={{ color: "#000" }}>
              Saved Menus
            </Text>
            <Text tag={"p"} className={`f14 regularText`} style={{ color: "rgba(177, 178, 180, 1)" }}>
              See your customized menus here.
            </Text>
          </header>
          <div className={"menuGrid"} ref={dropdownRef}>
            {menus?.data?.businessMenus?.map((item, index) => {
              if (!item?.isPublished) return <MenuCard item={item} index={index} />;
            })}
          </div>
        </section>
      );
    }
  };

  return (
    <>
      <main className={"aboutContainer"}>
        {renderEmptyState()}
        {renderLiveMenu()}
        {renderUnpublishedMenu()}
      </main>
    </>
  );
};

export default Menu;
