import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import Text from "../../utils/CustomText";
import { useMediaQuery } from "react-responsive";
import Button from "../../utils/CustomButton";

const AuthRedirectModalContainer = ({ closeModal, loginModal }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <section className={"authRedirectModalContainer"}>
      <div className={"authRedirectModalContent animate__animated animate__bounceIn"} style={{ width: isDesktopOrLaptop ? "416px" : "100%" }}>
        <header className={"authRedirectModalHeader flexRow alignCenter justifyBetween"}>
          <Text tag={"h5"} className={`boldText ${isDesktopOrLaptop ? "f20" : "f18"}`}>
            Already have an account?
          </Text>
          <button onClick={closeModal}>
            <GrClose />
          </button>
        </header>
        <div className={"authRedirectModalText"}>
          <Text tag={"p"} className={`regularText ${isDesktopOrLaptop ? "f18" : "f16"}`}>
            If you have a Dado account, log in with your Dado details to get started.
          </Text>
        </div>
        <footer className={"authRedirectModalFooter flexRow alignCenter justifyBetween flexWrap"} style={{ gap: "24px" }}>
          <Button
            text={"Continue to sign up"}
            style={{
              width: isDesktopOrLaptop ? "166px" : "100%",
              order: isDesktopOrLaptop ? "1" : "2",
              background: "inherit",
              border: "0.50px #999CA0 solid",
            }}
            onClick={closeModal}
          />
          <Button
            text={"Login"}
            style={{
              width: isDesktopOrLaptop ? "140px" : "100%",
              order: isDesktopOrLaptop ? "2" : "1",
              background: "#FF9000",
              border: "0.50px #FF9000 solid",
              color: "#fff",
            }}
            onClick={loginModal}
          />
        </footer>
      </div>
    </section>
  );
};

export default AuthRedirectModalContainer;
