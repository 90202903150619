import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { useMediaQuery } from "react-responsive";

import { useSelector } from "react-redux";
import Text from "../../../utils/CustomText";
import Button from "../../../utils/CustomButton";

const LogoutModal = ({ logOut, toggleLogOut }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <section className={`authModalContainer`} style={{ zIndex: "20" }}>
      <div className={`authModalContentContainer  ${isDesktopOrLaptop ? "animate__animated animate__fadeIn" : "animate__animated animate__slideInUp"}`} style={{ width: isDesktopOrLaptop ? "416px" : "100%" }}>
        <header className={" authModalContentHeader"}>
          <div className={"flexRow alignCenter justifyBetween"}>
            <Text tag={"h2"} className={`boldText textCenter pb8 ${isDesktopOrLaptop ? "f24" : "f20"}`}>
              Are you sure you want to logout?
            </Text>
            {/* <button onClick={toggleLogOut}>
              <GrClose />
            </button> */}
          </div>
        </header>
        <div className={"authModalFormContainer flexRow"} style={{ margin: "30px 0 0 0", gap: "24px" }}>
          <Button text={"Cancel"} onClick={toggleLogOut} style={{ background: "white", border: "1px solid #999CA0", color: "#000" }} />
          <Button text={"Logout"} className={"createMenuBtn"} onClick={logOut} />
        </div>
      </div>
    </section>
  );
};

export default LogoutModal;
