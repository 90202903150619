import http from "../../Utils";
import { GETTING_MENU, GETTING_MENU_FAIL, GETTING_MENU_SUCCESSFUL, GETTING_SINGLE_MENU, GETTING_SINGLE_MENU_FAIL, GETTING_SINGLE_MENU_SUCCESSFUL } from "../Types";

export const getAllMenu = (businessId) => {
    return dispatch => {
        dispatch({ type: GETTING_MENU })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`business/${businessId}/menus/all`)
                const data = res
                console.log('all menu ', res)
                dispatch({ type: GETTING_MENU_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                console.log('all menu', error)
                dispatch({ type: GETTING_MENU_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


export const getSingleMenu = (businessId, menuId) => {
    return dispatch => {
        dispatch({ type: GETTING_SINGLE_MENU })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`business/${businessId}/menu/${menuId}`)
                const data = res?.data
                console.log('single menu', res)
                dispatch({ type: GETTING_SINGLE_MENU_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                console.log('single menu', error)
                dispatch({ type: GETTING_SINGLE_MENU_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getSingleLiveMenu = (businessId, menuId) => {
    return dispatch => {
        dispatch({ type: GETTING_SINGLE_MENU })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`business/${businessId}/menu/${menuId}/live`)
                const data = res?.data
                console.log('single menu', res)
                dispatch({ type: GETTING_SINGLE_MENU_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                console.log('single menu', error)
                dispatch({ type: GETTING_SINGLE_MENU_FAIL, payload: error });
                reject(error);
            }
        });
    };
};
