import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import { SIGNOUT_USER_SUCCESSFUL } from '../Types';
import MenuReducers from './MenuReducers';
import BusinessReducers from './BusinessReducers';



const appReducer = combineReducers({
    auth: AuthReducer,
    menu: MenuReducers,
    business: BusinessReducers,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === SIGNOUT_USER_SUCCESSFUL) {
        state.auth = null;
    }

    return appReducer(state, action);
};

export default rootReducer;