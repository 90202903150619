import React from 'react'
import Text from '../utils/CustomText'
import { useMediaQuery } from 'react-responsive'
import AppStore from '../assets/images/appstore.svg';
import PlayStore from '../assets/images/playstore.svg';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillYoutube, AiOutlineFacebook, AiOutlineInstagram, AiOutlineLinkedin, AiOutlineTwitter, AiOutlineYoutube } from 'react-icons/ai';

const Footer = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const now = new Date();
    const goToPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.dado.ng', '_blank', 'noreferrer');
    }

    const goToAppStore = () => {
        window.open('https://apps.apple.com/us/app/dado-food/id6444361467', '_blank', 'noreferrer');
    }


    return (
        <footer className={'footerContainer'}>
            <section className={'flexRow justifyBetween flexWrap'} style={{ gap: '20px' }}>

                <div style={{ width: isDesktopOrLaptop ? '480px' : '100%' }}>
                    <Text tag={'h2'} className={`boldText ${isDesktopOrLaptop ? 'f48' : 'f38 pb24'}`}
                        style={{ color: '#F9F9FB', }}
                    >
                        Dado your best choice for local food!!!
                    </Text>
                </div>

                <ul>
                    <li className={'pb16'}>
                        <Text className={'f16 regularText'}
                            style={{ color: '#98A2B3' }}
                        >Let’s do this together</Text>
                    </li>
                    <li className={'pb16'}>
                        <a href='https://dado.ng/couriers'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >For couriers</Text>
                        </a>

                    </li>
                    <li className={'pb16'}>
                        <a href='https://dado.ng/vendors'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >For vendors</Text>
                        </a>
                    </li>
                    <li className={'pb16'}>
                        <Text className={'f16 regularText'}
                            style={{ color: '#E4E7EC' }}
                        >Virtual menu service</Text>
                    </li>

                </ul>
                <ul>
                    <li className={'pb16'}>
                        <Text className={'f16 regularText'}
                            style={{ color: '#98A2B3' }}
                        >Follow us</Text>
                    </li>
                    <li className={'pb16'}>
                        <a href='https://instagram.com/dadofoodng?igshid=NDc0ODY0MjQ='
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >Instagram</Text>
                        </a>
                    </li>
                    <li className={'pb16'}>
                        <a href='https://twitter.com/dadofood'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >Twitter</Text>
                        </a>
                    </li>
                    <li className={'pb16'}>
                        <a href='https://m.facebook.com/dadofoods'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >Facebook</Text>
                        </a>
                    </li>
                    <li className={'pb16'}>
                        <a href='https://ng.linkedin.com/company/dadochops'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >LinkedIn</Text>
                        </a>
                    </li>
                    <li className={'pb16'}>
                        <a href='https://m.youtube.com/channel/UCRpeaWxAKKN_K4lEnQKLOXA'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >YouTube</Text>
                        </a>
                    </li>


                </ul>
                <ul>
                    <li className={'pb16'}>
                        <Text className={'f16 regularText'}
                            style={{ color: '#98A2B3' }}
                        >Useful links</Text>
                    </li>
                    <li className={'pb16'}>
                        <a href='https://dado.ng/privacy'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >Privacy</Text>
                        </a>
                    </li>
                    <li className={'pb16'}>
                        <a href='https://dado.ng/terms'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text className={'f16 regularText'}
                                style={{ color: '#E4E7EC' }}
                            >Terms and Condition</Text>
                        </a>
                    </li>
                    <li className={'pb16'} onClick={() => window.location = 'mailto:support@dado.ng'}>

                        <Text className={'f16 regularText'}
                            style={{ color: '#E4E7EC' }}
                        >support@dado.ng</Text>

                    </li>

                </ul>
                <ul>
                    <li className={'pb16'}>
                        <Text className={'f16 regularText'}
                            style={{ color: '#E4E7EC' }}
                        >Get the app</Text>
                    </li>
                    <li className={'pb16'}>
                        <img src={AppStore} alt='image' onClick={goToAppStore} decoding='async' />
                    </li>
                    <li className={'pb16'}>
                        <img src={PlayStore} alt='image' onClick={goToPlayStore} decoding='async' />
                    </li>
                </ul>

            </section>
            <div className='divider' />
            <section className={`flexRow alignCenter flexWrap ${isDesktopOrLaptop ? 'justifyBetween' : 'justifyCenter'}`}>
                <Text tag={'p'} className={isDesktopOrLaptop ? 'f16 regularText' : 'f16 regularText'} style={{ color: '#98A2B3', }}>
                    © 2023 Dado foods. All rights reserved.
                </Text>
                <ul className={'flexRow alignCenter'} style={{ gap: '16px', margin: isDesktopOrLaptop ? '0' : '10px 0 0 0' }}>
                    <li>
                        <a href='https://twitter.com/dadofood'
                            target="_blank"
                            rel="noreferrer"
                        >

                            <AiOutlineTwitter color='#98A2B3' size={24} />
                        </a>
                    </li>
                    <li>
                        <a href='https://instagram.com/dadofoodng?igshid=NDc0ODY0MjQ='
                            target="_blank"
                            rel="noreferrer"
                        >
                            {/* <img src={InstagramIcon} alt={'instagram icon'} /> */}
                            <AiFillInstagram color='#98A2B3' size={24} />
                        </a>
                    </li>
                    <li>
                        <a href='https://m.facebook.com/dadofoods'
                            target="_blank"
                            rel="noreferrer"
                        >
                            {/* <img src={FacebookIcon} alt={'facebook icon'} /> */}
                            <AiFillFacebook color='#98A2B3' size={24} />
                        </a>
                    </li>
                    <li>
                        <a href='https://ng.linkedin.com/company/dadochops'
                            target="_blank"
                            rel="noreferrer"
                        >
                            {/* <img src={LinkedInIcon} alt={'linkedIn icon'} /> */}
                            <AiFillLinkedin color='#98A2B3' size={24} />
                        </a>
                    </li>


                    <li>
                        <a href='https://m.youtube.com/channel/UCRpeaWxAKKN_K4lEnQKLOXA'
                            target="_blank"
                            rel="noreferrer"
                        >
                            {/* <img src={YoutubeIcon} alt={'youtube icon'} /> */}
                            <AiFillYoutube color='#98A2B3' size={24} />
                        </a>
                    </li>

                </ul>
            </section>
        </footer>
    )
}

export default Footer