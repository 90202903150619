import React, { useState } from "react";
import CustomInput from "../../utils/CustomInput";
import Button from "../../utils/CustomButton";
import Text from "../../utils/CustomText";
import { useMediaQuery } from "react-responsive";
import { signUpReq } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { AUTO_LOGIN_DETAILS } from "../../redux/Types";
import { showError } from "../../utils/Alert";

const SignUpForm = ({ toggleVerify }) => {
  const dispatch = useDispatch();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [state, setState] = useState({
    email: "",
    password: "",
    error: [],
    loading: false,
  });

  const onChangeEmail = (e) => {
    setState({ ...state, email: e.target.value });
  };
  const onChangePassword = (e) => {
    setState({ ...state, password: e.target.value });
  };

  const disabled = () => {
    const { email, password, loading } = state;
    if (email === "" || password === "" || loading) {
      return true;
    }
    return false;
  };

  const signUp = async () => {
    const { email, password } = state;
    const obj = { email, password };
    setState({ ...state, loading: true });
    try {
      const res = await dispatch(signUpReq(obj));
      if (res) {
        dispatch({ type: AUTO_LOGIN_DETAILS, payload: obj });
      }
      toggleVerify();
      setState({ ...state, loading: false });
      console.log(res, "login res");
    } catch (error) {
      setState({ ...state, loading: false });
      showError(error);
      console.log(error);
    }
  };

  return (
    <section>
      <CustomInput type={"email"} label={"Email address*"} placeholder={"Enter email address "} onChange={onChangeEmail} />
      <CustomInput type={"password"} label={"Password*"} placeholder={"Enter unique password "} onChange={onChangePassword} />
      <Button text={"Sign up"} className={"authButton"} style={{ background: "#FF9000", marginTop: "20px", opacity: disabled() && "0.4" }} onClick={signUp} disabled={disabled()} loading={state.loading} />
      <div className={"termsContainer"} style={{ width: isDesktopOrLaptop ? "319px" : "100%" }}>
        <Text tag={"p"} className={"f12 regularText textCenter"} style={{ color: "#919294" }}>
          By continuing you agree to the
          <Text tag={"a"} className={"f14 mediumText"} style={{ color: "#181819", textDecoration: "underline", cursor: "pointer" }} href="https://dado.ng/privacy">
            {" "}
            Privacy{" "}
          </Text>
          <Text tag={"a"} className={"f14 mediumText"} style={{ color: "#181819", textDecoration: "underline", cursor: "pointer" }} href="https://dado.ng/terms">
            Terms & Conditions{" "}
          </Text>
          without reservation
        </Text>
      </div>
    </section>
  );
};

export default SignUpForm;
