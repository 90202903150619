import React, { useState } from 'react';
import Text from '../utils/CustomText';
import CustomInput from '../utils/CustomInput';
import Button from '../utils/CustomButton';
import { useMediaQuery } from 'react-responsive';
// import CustomInput from '../utils/CustomInput';



const NewsLetterComponent = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    // const [state, setState] = useState({
    //     email: "", loading: false
    // })

    // const onChangeEmail = (e) => {
    //     setState({ ...state, email: e.target.value })
    // }


    // const disable = () => {
    //     if (state.email === '') {
    //         return true
    //     }
    //     return false
    // }

    // // comment 
    // const subscribe = async () => {
    //     setState({ ...state, loading: true, })
    //     const { email } = state
    //     const obj = { email }
    //     try {
    //         const res = await http.post(`newsletter/subscribe`, obj)
    //         // console.log('subscribe', res)
    //         showSuccess(res.message)
    //         setState({ ...state, loading: false })
    //         if (res) {
    //             mixpanel.track('news-letter')
    //         }
    //     } catch (error) {
    //         // console.log('subscribe', error)
    //         setState({ ...state, loading: false })
    //     }
    // }


    return (
        <>
            <section className={'newsLetterContainer flexRow alignCenter justifyBetween flexWrap'}>
                <div>
                    <Text tag={'h2'} className={'f20 boldText pb8'} style={{ color: '#fff' }} >
                        Join our newsletter
                    </Text>
                    <Text tag={'p'} className={'f16 regularText'} style={{ color: '#E4E7EC' }} >
                        We promise to keep you informed and updated.
                    </Text>

                </div>
                <div className={`flexRow alignCenter  ${isDesktopOrLaptop ? 'noWrap' : 'flexWrap'}`} style={{ width: isDesktopOrLaptop ? 'auto' : '100%', gap: '10px' }}>
                    <input className='input' style={{ width: '100%' }} />
                    <Button text={'Subscribe'}
                        style={{
                            width: isDesktopOrLaptop ? "107px" : '100%', border: 'none',
                            background: '#FFA024', color: '#fff'
                        }}
                        disable={true}
                    />
                </div>
            </section >
        </>
    )
}

export default NewsLetterComponent