import React, { useEffect, useState } from "react";
import Text from "../../utils/CustomText";
import TemplatesCard from "../../components/dashboard/templates/TemplatesCard";
import Image from "../../assets/images/templates/template1.svg";
import { Template1, Template10, Template11, Template12, Template2, Template3, Template4, Template5, Template6, Template7, Template8, Template9 } from "../../assets/images/templates/Index";
import { useNavigate } from "react-router-dom";
import http from "../../Utils";
import { retrieveStoredId, storeBusiness } from "../../Helpers";
import CreateMenuModal from "../../components/dashboard/CreateMenuModal";
import { useSelector } from "react-redux";
const images = [
  {
    name: "template1",
    src: Template1,
  },
  // {
  //   name: "template2",
  //   src: Template2,
  // },
  // {
  //   name: "template3",
  //   src: Template3,
  // },
  // {
  //   name: "template4",
  //   src: Template4,
  // },
  // {
  //   name: "template5",
  //   src: Template5,
  // },
  // {
  //   name: "template6",
  //   src: Template6,
  // },
  // {
  //   name: "template7",
  //   src: Template7,
  // },
  // {
  //   name: "template8",
  //   src: Template8,
  // },
  // {
  //   name: "template9",
  //   src: Template9,
  // },
  // {
  //   name: "template10",
  //   src: Template10,
  // },
  // {
  //   name: "template11",
  //   src: Template11,
  // },
  // {
  //   name: "template12",
  //   src: Template12,
  // },
];

const Templates = () => {
  const navigate = useNavigate();
  const [createModal, setCreateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const { businessProfile } = useSelector((state) => state.auth);

  const getTemplates = async (val) => {
    try {
      const res = await http.get(`business/${val}/menus/templates`);
      console.log(res, "templates");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Call the retrieveStoredId function here
    if (businessProfile) {
      getTemplates(businessProfile?.business?.id);
    }
  }, []);

  const selectTemplate = (val) => {
    setCreateModal(true);
    setSelectedTemplate(val?.name);
  };
  const closeCreateModal = () => {
    setCreateModal(false);
  };

  return (
    <>
      <main className={"accountsContainer"}>
        <header>
          <Text tag={"h2"} className={"f24 boldText"}>
            Get started
          </Text>
          <Text tag={"p"} className={"f14 regularText"}>
            Choose a template and customize it.
          </Text>
        </header>
        <section className="templatesGridContainer">
          {images?.map((image, index) => {
            return <TemplatesCard src={image.src} key={index} onClick={() => selectTemplate(image)} />;
          })}
        </section>
      </main>
      {createModal && <CreateMenuModal title={"Create Menu"} closeAuth={closeCreateModal} selectedTemplate={selectedTemplate} />}
    </>
  );
};

export default Templates;
