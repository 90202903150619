import { GETTING_BUSINESS_PROFILE, GETTING_BUSINESS_PROFILE_FAIL, GETTING_BUSINESS_PROFILE_SUCCESSFUL } from "../Types";

const INITIAL_STATE = {
    loading: false,
    error: [],
    businessProfile: {},

};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case GETTING_BUSINESS_PROFILE:
            return { ...state, loading: true };
        case GETTING_BUSINESS_PROFILE_SUCCESSFUL:
            return { ...state, loading: false, businessProfile: payload }
        case GETTING_BUSINESS_PROFILE_FAIL:
            return { ...state, loading: false, error: payload }

        default:
            return state;
    }
};