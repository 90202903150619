import { LOGIN_SUCCESS, LOGIN_FAIL, LOADING_USER, SIGNOUT_USER_FAIL, GETTING_OTP, GETTING_OTP_SUCCESSFUL, GETTING_OTP_FAIL, SIGNOUT_USER, SIGNOUT_USER_SUCCESSFUL, VERIFY_OTP, VERIFY_OTP_SUCCESSFUL, VERIFY_OTP_FAIL, SETUP_BUSINESS, SETUP_BUSINESS_SUCCESSFUL, SETUP_BUSINESS_FAIL, AUTO_LOGIN_DETAILS } from '../Types';

const INITIAL_STATE = {
    loading: false,
    error: [],
    userData: {},
    alertMessage: null,
    loggedIn: false,
    verifyToken: '',
    businessProfile: '',
    autoLoginDetails: {},
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case GETTING_OTP:
            return { ...state, loading: true };
        case GETTING_OTP_SUCCESSFUL:
            return { ...state, loading: false, verifyToken: payload.data }
        case GETTING_OTP_FAIL:
            return { ...state, loading: false, error: payload }
        case VERIFY_OTP:
            return { ...state, loading: true }
        case VERIFY_OTP_SUCCESSFUL:
            return { ...state, loading: false }
        case VERIFY_OTP_FAIL:
            return { ...state, loading: false, error: payload }
        case AUTO_LOGIN_DETAILS:
            return { ...state, autoLoginDetails: payload }
        case LOADING_USER:
            return { ...state, loading: true, error: [] };
        case LOGIN_SUCCESS:
            return { ...state, userData: payload, loading: false, loggedIn: true, businessProfile: payload };
        case LOGIN_FAIL:
            return { ...state, error: payload, userData: {}, loading: false };
        case SETUP_BUSINESS:
            return { ...state, loading: true, error: [] };
        case SETUP_BUSINESS_SUCCESSFUL:
            return { ...state, businessProfile: payload, loading: false, };
        case SETUP_BUSINESS_FAIL:
            return { ...state, error: payload, businessProfile: {}, loading: false };
        case SIGNOUT_USER:
            return { ...state, loggedIn: false, userData: {}, }
        case SIGNOUT_USER_SUCCESSFUL:
            return { ...state, loading: false, loggedIn: false, userData: {}, verifyToken: '', businessProfile: "", autoLoginDetails: "" }
        case SIGNOUT_USER_FAIL:
            return { ...state, error: payload };

        default:
            return state;
    }
};