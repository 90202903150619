import React, { useState, useRef, useEffect } from "react";
import Text from "../../utils/CustomText";

const CustomDropdown = ({ selectedOption, setSelectedOption }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = ["Restaurant", "Bar", "Hotel", "Private individual", "Event Organizer"];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  return (
    <section>
      <Text tag={"label"} className={"f12 mediumText"} style={{ color: "#000" }}>
        Business type*
      </Text>

      <div className="custom-dropdown" ref={dropdownRef}>
        <input type="text" value={selectedOption} placeholder="Choose business type" onClick={() => setIsDropdownOpen(!isDropdownOpen)} readOnly />
        <div className={`caret ${isDropdownOpen ? "open" : ""}`}></div>
        {isDropdownOpen && (
          <ul className="dropdown-list">
            {options.map((option) => (
              <li key={option} onClick={() => handleOptionClick(option)}>
                <Text tag={"p"} className={"f14 regularText"} style={{ color: "#000" }}>
                  {option}
                </Text>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default CustomDropdown;
