import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import { useMediaQuery } from "react-responsive";
import Text from "../../utils/CustomText";
import CustomInput from "../../utils/CustomInput";
import Button from "../../utils/CustomButton";
import { retrieveStoredBusiness, retrieveStoredId, storeBusiness, storeBusinessMenu } from "../../Helpers";
import http from "../../Utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_MENU } from "../../redux/Types";

const CreateMenuModal = ({ title, closeAuth, selectedTemplate }) => {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [loading, setLoading] = useState(false);
  const [menuName, setMenuName] = useState("");
  const [menuDescription, setMenuDescription] = useState("");
  const { businessProfile } = useSelector((state) => state.auth);

  const onChangeMenuName = (e) => {
    setMenuName(e.target.value);
  };
  const onChangeMenuDescription = (e) => {
    setMenuDescription(e.target.value);
  };

  const dispatch = useDispatch();
  const proceed = async () => {
    setLoading(true);
    const menu_name = menuName;
    const menu_description = menuDescription;
    const menu_email = businessProfile?.business?.businessEmail;
    const menu_address = businessProfile?.business?.businessAddress;
    const menu_phone_number = businessProfile?.business?.businessPhone[0];
    const social_media_urls = businessProfile?.business?.businessSocialMediaUrl;
    const template_name = selectedTemplate;

    const obj = { menu_name, menu_description, menu_email, menu_address, menu_phone_number, social_media_urls, template_name };
    console.log(obj, "obj");
    try {
      const res = await http.post(`business/${businessProfile?.business?.id}/menus/`, obj);
      console.log(res, "menu created");
      dispatch({ type: UPDATE_MENU });

      navigate(`menuMaker/${selectedTemplate}`, {
        state: { data: res?.data },
      });
      closeAuth();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <section className={`authModalContainer`}>
      <div className={`authModalContentContainer  ${isDesktopOrLaptop ? "animate__animated animate__fadeIn" : "animate__animated animate__slideInUp"}`} style={{ width: isDesktopOrLaptop ? "416px" : "100%" }}>
        <header className={" authModalContentHeader"}>
          <div className={"flexRow alignCenter justifyBetween"}>
            <Text tag={"h2"} className={`boldText textCenter pb8 ${isDesktopOrLaptop ? "f24" : "f20"}`}>
              {title}
            </Text>
            <button onClick={closeAuth}>
              <GrClose />
            </button>
          </div>
        </header>
        <div className={"authModalFormContainer"} style={{ margin: "10px 0" }}>
          <CustomInput label={"Menu Name"} type={"text"} placeholder={"Enter business name"} onChange={onChangeMenuName} />
          <CustomInput label={"Menu Description"} type={"text"} placeholder={"Enter business description"} onChange={onChangeMenuDescription} />
          <Button text={"Create Menu"} className={"createMenuBtn"} loading={loading} onClick={proceed} />
        </div>
      </div>
    </section>
  );
};

export default CreateMenuModal;
