import React from "react";
import Text from "../../utils/CustomText";
import Button from "../../utils/CustomButton";
import Avatar from "../../assets/images/Avatar.svg";
import CustomInput from "../../utils/CustomInput";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

const Settings = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const { businessProfile } = useSelector((state) => state.auth);
  return (
    <main className={"accountsContainer"}>
      <section className={"flexRow alignCenter justifyBetween flexWrap"}>
        <div>
          <Text tag={"h3"}>Business profile settings</Text>
          <Text tag={"p"}>Update your business profile settings.</Text>
        </div>
        {/* {isDesktopOrLaptop && (
          <Button
            text={"Save changes"}
            style={{
              marginTop: "20px",
              background: "#FFA024",
              border: "none",
              color: "#fff",
            }}
          />
        )} */}
      </section>
      <section style={{ margin: "32px 0 40px 0", height: "150px", width: "150px", borderRadius: "99px" }}>
        <img src={businessProfile?.business?.businessLogo} alt="avatar" defaultValue={Avatar} style={{ height: "100%", width: "100%", borderRadius: "99px", objectFit: "cover" }} />
      </section>
      <section className={"profileDetailsGrid"}>
        <CustomInput label={"Name of business*"} value={businessProfile?.business?.businessName} readOnly={true} />
        <CustomInput label={"Address*"} value={businessProfile?.business?.businessAddress} readOnly={true} />
        <CustomInput label={"Phone number *"} value={businessProfile?.business?.businessPhone[0]} readOnly={true} />
        <CustomInput label={"Email address *"} value={businessProfile?.business?.businessEmail} readOnly={true} />
        <CustomInput label={"Business type*"} value={businessProfile?.business?.businessType} readOnly={true} />
        <CustomInput label={"Website URL"} value={businessProfile?.business?.businessWebsite} readOnly={true} />
      </section>
      {/* <section>
        <div style={{ margin: "30px 0" }}>
          <Text tag={"h3"} className={"f24 boldText"}>
            Change password
          </Text>
          <Text tag={"p"} className={"f14 regularText"}>
            A verification email will be sent to @{businessProfile?.business?.businessEmail} before password can be successfully changed.
          </Text>
        </div>
        <div className={"profileDetailsGrid"}>
          <CustomInput label={"Old password"} type={"password"} />
          <CustomInput label={"New password"} type={"password"} />
        </div>
      </section>
      {!isDesktopOrLaptop && (
        <section>
          <Button
            text={"Save changes"}
            style={{
              width: "100%",
              marginTop: "20px",
              background: "#FFA024",
              border: "none",
              color: "#fff",
            }}
          />
        </section>
      )} */}
    </main>
  );
};

export default Settings;
